import { Service } from '../../framework'
import { getSafe } from '../../framework/utils/helper'

import { Participation} from '../../entities'
import { EmploymentService, MembershipService }  from '..'
import ParticipationBusiness from '../../business/ParticipationBusiness'
import { RefEvent } from '../../framework/infra'
import { setAPIError } from '../../hooks/useAPIError'

class ParticipationService extends Service {
	constructor() {
        super(Participation, 'Participation', 'membership')
    }

	updateParticipation(participation) {
		return this.persistence.update(this.moduleName + '_Update' + this.serviceName, participation, null ,{ppKey: participation.keyValue}).then(inst => {
			const response = this._setPromise(inst.keyValue, Promise.resolve(inst))
			this.invalidateCache();
			return response;
		})
	}

	get(key = '', options = {}) {
		const keys = Participation.getKeyValues(key) 
		return MembershipService.get(keys.membership, options).then(mem => {
			return getSafe(mem, 'participations.' + key)
		}).then(participation => {
			if (options.init !== false) this.init(participation)
			return participation
		})
	}
	
	getAll(options = {}) {
		return MembershipService.getMemberships(options).then(memberships => {
			return memberships.reduce((parts, mem) => {
				parts.pushList(mem.participations)
				return parts
			}, this.toRefList([]))
		})
	}

	getMemberParticipations(memberId = '', options = {}) {
		return MembershipService.get(memberId, options).then(mem => {
			return mem.participations
		})
	}

	deleteParticipation(participation){
        this.invalidateCache();
		return this.persistence
            .callApi(
                this.moduleName + "_DeleteParticipation", {}, { keys: participation.keyValue }
            )
            .catch((err) => {
                setAPIError(err);
                console.log(err);
                throw err;
            });;
	}
	
	async load(participation, options = {}) {
		for (const event of participation.events.all) {
			for (const pointer of event.pointers.all) {
				await pointer.load();
			}
		}
	}

	init(participation) {
		participation.events.sortEvents();
		return ParticipationBusiness.validate(participation)
	}

	transferEmployment(participation) {
        const etsHiredDt = participation.employments.last.getHiredEvent().ets;
        participation.employments.last.getHiredEvent().ets += 100;
        participation.employments.last.addEvent({
            code: "lgcyTri",
            ets: etsHiredDt + 10,
        });
        participation.employments._list[
            participation.employments.length - 2
        ].addEvent({ code: "tro", ets: etsHiredDt });
        EmploymentService.update(participation.employments.last).then(() =>
            EmploymentService.update(
                participation.employments._list[
                    participation.employments.length - 2
                ]
            )
        );
    }
}

const singelton = new ParticipationService()
export default singelton
