import { Definition, RefTask } from '../../../framework/infra'
import RefDescription from '../../../framework/infra/model/RefDescription'
import ParticipationEvent from './ParticipationEvent'
import { ParticipationTaskConfig } from './ParticipationTaskConfig'

export default class ParticipationTask extends RefTask {
    get config() { return { ...ParticipationTaskConfig['default'], ...ParticipationTaskConfig[this.code]}}
    get form() {return ParticipationTaskConfig[this.code]?.form || ParticipationTaskConfig['default'].form }
    get formalDescription() {
        const formalDescription = ParticipationTaskConfig[this.code]?.formalDescription;
        const newDesc = new RefDescription({key: this.code, text: formalDescription, name: this.name, options: this.options});
        return formalDescription ? newDesc?.getDesc(this.params) : (this.flowFormalDescription ?? this.desc);
    }
    hasFlow() {
        return ParticipationTaskConfig[this.code]?.form;
    }

    static messages = [
        ['age60Def', 'DEF60: (1) Age 60 @ {sixtiethBirthday}; Request pension docs', 'DEF60', 't'],
        ['over60Open','Member is 60 years old on {sixtiethBirthday} date. Confirm pension data with the employer and request pension documents.', 'Active & Over 60', 't'],
        ['over60Def','DEF60: (1) Age 60 @ {sixtiethBirthday}; Request pension docs', 'DEF60', 't'],
        ['age60Open', 'Member will be age 60 on {sixtiethBirthday} date. Confirm Age60 data & validate if eligible to Age60 Declaration.', 'Active & Age 60', 't'],
        ['age71Open', 'ACT71: Age71 @ {seventyfirstBirthday}. Pension must start 1-Dec-{yearAge71}. Send ACT71 email.', 'ACT71', 't'],
        ['age71Def', 'DEF71: Age71 @ {seventyfirstBirthday}. Pension must start 1-Dec-{yearAge71}. Send ACT71 email.', 'DEF71', 't'],
        ['procPay', '[Pay Auth] Process and send payment authorization to RBC', 'Pay Auth', 't', {possibleEvents: ['paySent']}],
        ['reqEnF', 'Request Enrollment Form', 'Request Enrollment Form', 't'],
        ['rfdtDoc', 'DEF60 - Pension documents requested - Waiting for employee to submit documents','DEF60 - Waiting for pension documents', 't'],
        ['rfdSend','Send calculation request to actuaries', '', 't'],
        ['rfdPenDt', 'Confirm the Pension Start date', '', 't'],
        
        
        ['ACT-SNP', '[ACT/SNP] Member is Eligible to unreduced pension while continuing to work. Age60 Declaration sent to employee & employer', 'ACT-SNP', 't'],
        ['ACT-OSO', '[ACT/OSO] Member elected to receive pension while continuing to work. Calculation request sent to Mercer.', 'ACT-OSO', 't'],
        ['ACT-TRF', '[ACT/TRF] Possible transfer to other jurisdiction.', 'ACT-TRF', 't'],
        ['LTD-SNP', '[LTD/SNP] Member on LTD & turned 60 on {dd-mmm-yy}. Emailed {ER} to determine whether member will continue accruing benefits or take an unreduced pension', 'LTD-SNP', 't'],
        ['LTD-SXT', '[LTD/SXT] Member on LTD & chose to commence pension at age 60. Calculation request sent to Mercer', 'LTD-SXT', 't'],
        ['LTD-OSO', '[LTD/OSO] Options package mailed by Mercer. Waiting for member to submit Election form', 'LTD-OSO', 't'],
        ['S-TNP', '[S/TNP] Last day at {ER}. Awaiting Termination Data from {ER}. Member must decide whether to transfer or terminate', 'S-TNP', 't'],
        ['S-OTF', '[S-OTF] Outstanding Transfer to {Other Juris} plan. Waiting for member election', 'S-OTF', 't'],
        ['T-ONP', '[T/ONP] Calculation request sent to Mercer', 'T-ONP', 't'],
        ['P-ONP', '[P/ONP] Calculation request sent to Mercer', 'P-ONP', 't'],
        ['P-OPO', '[P/OPO] Options package mailed by Mercer. Waiting for member to submit Election form', 'P-OPO', 't'],
        ['D-ODO', '[D/ODO] Options package mailed by Mercer. Waiting for member’s spouse/beneficiary(ies) to submit Election form', 'D-ODO', 't'],

        ['rhrdPen', 'Send Member Declaration: Rehiring a Pensioner form', 'Send Member Declaration', 't'],
        ['wPenFrm', 'Waiting for Member Declaration: Rehiring a Pensioner form from employee','Rehiring Pensioner waiting', 't'],
        ['penExpInf', 'Deadline for rehiring a pensioner expired - Inform employee that pension will not be suspended', 'Rehiring Pensioner expired', 't'],
        ['infEmpDed', 'Inform employee of automatic deductions', '', 't'],

        ['onbrd', 'Onboarding - In progress', 'Onboarding', 't'],
        ['onbrdDffJur', 'Send Membership sustained with new employer {Important change of Jurisdiction} notice to employee and employer.', '', 't'],
        ['onbrdSmJur', 'Send Membership sustained with new employer notice to employee and employer.', '', 't'],
        ['trfNotElg', 'Send Membership Transfer {Not Eligible} to employee and employer', '', 't'],
        ['act60', 'Age60 - In progress', '', 't'],
        ['cSpouInf', 'Member’s spouse is also a member. Amended spousal information does not match their member information. Please verify.', '', 't'],
        ['isPen', 'Process calculation request', 'Pending', 't'],
    ]

    static definitions = {
        form: {abstract: true},
        formalDescription: {abstract: true, type: Definition.types.STRING, text: "Employer Description"},
        flowFormalDescription: {abstract: true, type: Definition.types.STRING, text: "Employer Description"},
    }

    static validationTask(){
        return Object.values(this.messages).filter(task => ParticipationTaskConfig[task.key])
    }

    static alwaysShownTask(){
        return Object.values(this.messages).filter(task => ParticipationTaskConfig[task.key]?.alwaysShow)
    }


    static events = ParticipationEvent.messages
}
