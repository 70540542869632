import { RefMessage } from "../../framework/infra";
import { Definition, RefMessages } from "../../framework/infra/model";
import { RemittanceMessageConfig } from "./RemittanceMessageConfig";

export class RemittanceMessage extends RefMessage { 
    get config() { return {...RemittanceMessageConfig['default'] ,...RemittanceMessageConfig[this.code]}}
    get fixer() { return this.config.fixer};

    static messages = [
        ['nonEnroll', 'Not enrolled, no contributions owed', '', 'i'],
        ['terminated', 'Terminated, no contributions owed', '', 'i'],
        ['noHours',  "Enter Hours Worked or Hourly Wage Rate", '', 'w'],
        ['hoursNoEarnings',  "Hours Worked where entered without Earnings,\nEnter Earnings to continue", '', 'w'],
        ['noEarnings',  'No information received and the member is ACTIVE.\nEnter Earnings and Hours.\nIf member is on leave, change the status.', '', 'w'],
        ['negativeEarnings', 'Negative Earnings reported.\nEnter explanation in the "Comment" Column.', '', 'w'],
        ['earningsOnMaternity', 'Explain Earnings in "Comment" Column. Member is on maternity leave. If member returned to work, change status.', '', 'w'],
        ['earningsOnDisability', 'Explain Earnings in "Comment" Column.\nMember is on long term disability. If member returned to work, change status.' , '', 'w'],
        ['earningsOnLeave', 'Explain Earnings in "Comment" Column.\nMember is on leave. If member returned to work, amend status.', '', 'w'],
        ['earningsOnTerminated', 'Explain Earnings in "Comment" Column.\nMember is terminated. If member returned to work, change status.', '', 'w'],
        ['maternityEnded', 'Maternity Leave has ended on {endDate}, please change status', '', 'w'],
        ['maternityEndReminder', 'Maternity Leave has ended on {endDate}', '', 'i'],
        ['noBaseEarnings', 'Member on Leave, Provide Base Earnings.', '', 'w'],
        ['progressiveNoEarnings', 'Member is on Progressive Return however no earnings/hours have been reported. Enter Earnings/Hours or amend status.', '', 'w'],
        ['ltdToTerminated', 'Confirm the end date of the LTD benefit (Last day paid)', '', 'w', ''],
        ['ltdToTerminatedInfo', 'End date of LTD = {ltdEndDate}', '', 'w', ''],
        ['reducedDeemed', 'Progressive return: deemed contributions have been reduced by the regular contributions', '', 'w'],
        ['expectedDeemed', 'Actual deemed amounts do not match expected deemed amounts. Verify deemed calculations.', '', 'w'],
        ['maxContributionsReached', 'Check payroll deductions,\nmaximum contributions attained. Reimburse excess to Member.', '', 'i'],
        ['maxVoluntaryReached', 'Maximum allowable Voluntary Contributions of {max} was attained.', '', 'i'],
        ['maxVoluntaryExceeded', 'Exceeds Maximum allowable Voluntary Contributions of {max}.\nReimburse Member any excess', '', 'i'],
        ['ympeReached', 'Reconcile contributions invoiced with your payroll deductions; the YMPE was attained.', '', 'i'],
        ['exceedRegularHours', 'Regular hours reported exceed employee’s normal work schedule.\nCorrect or explain in "Comment" Column.', '', 'w'],
        ['midMonthEnr', 'Mid-month enrolment, Please enter pensionable earnings from {midMonthDt}', '', 'w',''],
        ['midMonthRet', 'Age-60 retirement, Please enter pensionable earnings up to {AGE60}', '', 'w',''],
        ['noEarningsAt60', 'No earnings reported: Confirm if the employee has been laid off or was fired/quit.', '', 'w',''],
        ['closeEarningAdj', 'Adjustment required for terminated member. Earnings and/or hours reported are different than original. See super admin.', '', 'e', ''],
        ['eligEarning', 'Earnings and/or Hours reported . Confirm the date of return to work', '', 'w', ''],

    ]

    static definitions = {
        config: {abstract: true},
        fixer: { abstract: true, type: Definition.types.UNDEFINED, text: 'Fixer' },
    }
}

export class RemittanceMessages extends RefMessages {
    static ref = RemittanceMessage
    
}