import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Component, EInput } from '../../framework/components'
import { Row, FieldSet, Title } from '../../framework/containers'
import uuid from 'uuid/v4'


import PersonSummary from '../person/PersonSummary'
import { SpouseService } from '../../services'
import { Check } from '../../framework/inputs'
import UnknownPersonForm from '../person/UnknownPersonForm'
import { Select } from '../../framework/controls'

const SpouseForm = ({spouse, onClose, onSave, onAdd, employers}) => { 

	const isNew = spouse.isNew();
	if (isNew) spouse.id = uuid();

	const [draft, _setDraft] = useState(spouse.clone());
	const [errors, setErrors] = useState({});

	const setDraft = (draft) => _setDraft(draft.clone())
	const handleCancel = () => { if (onClose) onClose() }
	const onPersonSave = () => { spouse.person = draft.person }

	const render = () => setDraft(draft);

	const handlePersonChange = (person) => {
		draft.person = person;
		setDraft(draft);
	}

	const handleHasSINToggle = () => {
		draft.noSin = !draft.noSin;
		setDraft(draft);
	}

	const handleSelectEmployer = (key) => {
		draft.employer = employers.find(x=>x.keyValue === key);
		draft.jurisdiction = draft.employer.jurisdictionCode;
		setDraft(draft);
	}

	const handleSave = (fn) => {
		if (isValid()) {
			if (fn) fn(SpouseService.preSave(draft));
			onClose()
		}
	}

	const isValid =  () => {
        const errors = SpouseService.validate(draft);
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return false;
        }
        return true;
    }

	const hasMaritalBreakdown = draft.maritalBreakDownOption && draft.maritalBreakDownOption !== '';
	const check = <Check name='sin' value={draft.noSin} label='SIN Missing' lcn='checkbox-inline' onChange={handleHasSINToggle} />
	const employerOptions = employers.map(opt => ({key: opt.keyValue, text: opt.code, value: opt.jurisdictionCode}))

	return <>
		<Title title={(isNew ?'New ':'') + 'Marital Information'} onHide={handleCancel}/>
		<FieldSet title='Marital Status' className='mt-1 mb-1' variant='field'>
			<div className="grid-ellipsis gel-4">
				<EInput name='effDt' showRequiredLabelMarker onChange={render} instance={draft} error={errors.effDt} label={'Designation Date'}/>
				<EInput name='mSts' showRequiredLabelMarker onChange={render} error={errors.mSts}  instance={draft}/>
				<EInput name='jurisdiction' label="Employer" instance={draft} error={errors.jurisdiction} >
					<Select options={employerOptions} value={draft?.employer?.keyValue} onChange={handleSelectEmployer} readOnly={false}/>
				</EInput> 
				<EInput name='jurisdiction' showRequiredLabelMarker error={errors.jurisdiction} instance={draft} readOnly={true}/>
            </div>
		</FieldSet>
		{ (!draft.isSingle && !draft.noSin) && <PersonSummary errors={errors} 
			onSave={onPersonSave}
			title={<Row> Spouse {check}</Row>} 
			allowPersonChange={true} person={draft.person} onSwitchPerson={handlePersonChange} onNewPerson={handlePersonChange}/> 
		}
		{ (!draft.isSingle && draft.noSin)  && <UnknownPersonForm errors={errors} person={draft.unknownPerson} 
			title={<Row> Spouse {check}</Row>} 
			/> 
		}
			<FieldSet title='Marital Information' className='mt-1 mb-1' variant='field'>
			{ !draft.isSingle && <>
				<div className="grid-ellipsis">
					<EInput name='taxationStatus' instance={draft} />
					<EInput name='maritalBreakDownOption' onChange={render} instance={draft} />
					{ hasMaritalBreakdown && <EInput name='maritalBreakDownDate' instance={draft}/>}
				</div>
				{ hasMaritalBreakdown && <div className="grid-ellipsis">
					<EInput name='judgementOrderReceivedOption' instance={draft} />
					<EInput name='maritalStatusDeclarationReceived' instance={draft} />
					<EInput name='judgementOrderReceivedOption' instance={draft} />
				</div> }
			</> }
				<div className="line g10">
					<EInput variant="textarea" name='cmt' instance={draft}/>
				</div>
			</FieldSet>
		<Row className='justify-content-end button-row mt20'>
			<Button onClick={() => handleSave(isNew ? onAdd : onSave)}> {isNew ? 'Add':'Save'} </Button>
		</Row>
	</>	
}

export default SpouseForm;