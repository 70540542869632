import moment from "moment"
import TaskPage from "../../framework/components/page/TaskPage"
import CalendarValidation from "../../framework/components/taskValidation/CalendarValidation"
import MultipleChoiceValidation from "../../framework/components/taskValidation/MultipleChoiceValidation"
import AdjustmentValidation from "../../framework/components/taskValidation/AdjustmentValidation/AdjustmentValidation"
import { MemSustainedTask } from "./TaskConfig/MemSustainedTask"
import config from "../../utils/config"
import YesNoQuestion from "../../framework/components/taskValidation/YesNoValidation"
//import { confirmHrdDate } from "./TaskConfig/ConfirmHrdDate"

export const EmploymentTaskConfig = {
    default: {
        form: <TaskPage />
    },
    baseMonthlyEarnings: {
        validation: (employment) => {
            return employment.baseEarnings === 0 && employment.onLeaveNoBaseEarnings();
        },
        removeValidation: (employment) => {
            return employment.baseEarnings !== 0 || !employment.onLeaveNoBaseEarnings();
        }
    },
    selfContrib: {
        params: (employment, event) => [
            { key: 'effectiveDate', value: event.effDt }, 
            { key: 'endOfNextMonth', value: moment(event.effDt).add(1, 'months').endOf('month').format("YYYY-MM-DD") }, 
            { key: 'ets', value: event.ets }, 
            { key: 'mts', value: event.mts }
        ],
        validation: (employment) => {return employment.events.getLeavesWithoutSelfEvents()},
        form: <MultipleChoiceValidation 
            choice={[
                {desc: 'Member accepted to self contribute', event: {code: 'asc'}},
                {desc: 'Member declined to self contribute', event: {code: 'dsc'}},
                {desc: 'No self contributions – Offer expired', event: {code: 'esc'}}
            ]} 
            header={'Confirm if the employee will make self-contributions for the leave with effective date: '}
            onYes={(employment, selectEvent, paramValue) => {
                employment.addEvent({
                    ...selectEvent,
                    ets: paramValue.ets.value + 10,
                    mts: paramValue.mts.value + 10,
                })
            }}
            isEmployment
            paramName="effectiveDate"
        />,
        showMultiple: true,
    },
    isStdOver15weeks: {
        validation: (employment) => {return employment.isStdOver15weeks()}
    },
    isMaternityOver18weeks: {
        params: (employment) =>  [
            {key: 'maternityEndDate', value: moment(employment.eventStatuses.last.effDt).add((employment.employer.plan.currentRates.maternityDuration) - 1, 'days').format('YYYY-MM-DD') },
            {key: 'maternityEndDateNextDay', value: moment(employment.eventStatuses.last.effDt).add((employment.employer.plan.currentRates.maternityDuration), 'days').format('YYYY-MM-DD') },
            {key: 'maternityEndDateNextMonth', value: moment(employment.eventStatuses.last.effDt).add((employment.employer.plan.currentRates.maternityDuration), 'days').add(1, 'months').endOf('month').format('YYYY-MM-DD') }
        ],
        validation: (employment) => {return employment.isMaternityOver18weeks()},
        formalDescription: "Maternity ends {maternityEndDate}. Eligible to self contribute during parental leave commencing {maternityEndDateNextDay}. Inform ASHAYES of employee’s decision. Offer expires {maternityEndDateNextMonth}"
    },
    hasOutstandingPackageFed: {
        params: (employment) => [{key: 'outstandingEndDate', value: moment(employment.participation.events.last.effDt).add(180, 'days').format('YYYY-MM-DD')}],
        validation: (employment) => {
            if(!employment.participation?.hasOutstandingPkgOver6Months) {
                console.warn('EmploymentTaskConfig hasOutstandingPackageFed validation: employment.participation.hasOutstandingPkgOver6Months is undefined/null, data might be corrupted');
            }
            return employment.employer.plan.isFederal() && employment.participation?.hasOutstandingPkgOver6Months?.();
        },
        removeValidation: (employment) => {
            if(!employment.participation?.hasOutstandingPkgOver6Months) {
                console.warn('EmploymentTaskConfig hasOutstandingPackageFed removeValidation: employment.participation.hasOutstandingPkgOver6Months is undefined/null, data might be corrupted');
            }
            return !employment.employer.plan.isFederal() && !employment.participation?.hasOutstandingPkgOver6Months?.();
        }
    },
    hasOutstandingPackageQue: {
        params: (employment) => [{key: 'outstandingEndDate', value: moment(employment.participation.events.last.effDt).add(180, 'days').format('YYYY-MM-DD')}],
        validation: (employment) => {
            if(!employment.participation?.hasOutstandingPkgOver6Months) {
                console.warn('EmploymentTaskConfig hasOutstandingPackageQue validation: employment.participation.hasOutstandingPkgOver6Months is undefined/null, data might be corrupted');
            }
            return employment.employer.plan.isQuebec() && employment.participation?.hasOutstandingPkgOver6Months?.();
        },
        removeValidation: (employment) => {
            if(!employment.participation?.hasOutstandingPkgOver6Months) {
                console.warn('EmploymentTaskConfig hasOutstandingPackageQue removeValidation: employment.participation.hasOutstandingPkgOver6Months is undefined/null, data might be corrupted');
            }
            return !employment.employer.plan.isQuebec() && !employment.participation?.hasOutstandingPkgOver6Months?.();
        }
    },
    hasUnclaimedBenefitQue: {
        params: (employment) => [{key: 'unclaimedBenefitDate', value: moment(employment.participation.events.last.effDt).format('YYYY-MM-DD')}],
        validation: (employment) => {
            if(!employment.participation?.isPackageSent()) {
                console.warn('EmploymentTaskConfig hasUnclaimedBenefitQue validation: employment.participation.isPackageSent() is undefined/null, data might be corrupted');
            }
            return employment.employer.plan.isQuebec() && employment.participation?.isPackageSent?.();
        },
        alwaysShow: true,
        // removeValidation: (employment) => {
        //     if(!employment.participation?.hasOutstandingPkgOver6Months) {
        //         console.warn('EmploymentTaskConfig hasUnclaimedBenefitQue removeValidation: employment.participation.hasOutstandingPkgOver6Months is undefined/null, data might be corrupted');
        //     }
        //     console.log('test:', !employment.participation?.hasOutstandingPkgOver6Months?.())
        //     return !employment.employer.plan.isQuebec() && !employment.participation?.hasOutstandingPkgOver6Months?.();
        // }
    },
    signTermOpt: {
        params: (employment) => {return [{key: 'firedQuitDate', value: moment(employment.eventStatuses.last.effDt).format('YYYY-MM-DD')}]},
        validation: (employment) => {return employment.isFiredQuitPackageSent()}
    },
    signPenOpt: {
        params: (employment) => [{key: 'retiredDate', value: moment(employment.eventStatuses.last.effDt).format('YYYY-MM-DD')}],
        validation: (employment) => {return employment.isRetiredPackageSent()}
    },
    onLeaveOverOneYear: {
        validation: (employment) => {return employment.isOnLeaveOverOneYear()}
    },
    confirmFrstDay: {
        validation: (employment) => {
            const pp = employment.participation;
            if(pp?.employments?.length === undefined || pp?.employments?.length === null) {
                console.warn('EmploymentTaskConfig confirmFrstDay validation: pp.employments.length is undefined/null, data might be corrupted');
            }
            if(typeof pp?.employments?.length === 'number' && pp.employments.length < 2) return false;
            return employment.isStillEligible();
        },
        form: <CalendarValidation 
            dateLabel='Join Date' 
            taskSave={(participation, joinDt, comment) => 
                participation.addEvent({code: 'metElig', ets: moment(joinDt).valueOf(), cmt: comment})
            } 
            message='Enter the first day of work'
        />
    },
    deemedCheck: {
        validation: (employment) => employment.events.find(e => e.config.isDeemedCheck),
        form: <AdjustmentValidation title="Deemed Adjustment" isEmployment />,
        formalDescription: " ",
    },
    memStask: {
        validation: (employment) => {
            if(!employment?.participation?.status?.isActive){
                console.warn('EmploymentTaskConfig memStask validation: employment.participation.status.isActive is undefined/null, data might be corrupted');
            }
            if(!employment?.participation?.isPendingClose){
                console.warn('EmploymentTaskConfig memStask validation: employment.participation.isPendingClose is undefined/null, data might be corrupted');
            }
            let enrolled = employment?.participation?.status?.isActive?.() && !employment?.participation?.isPendingClose?.();
            let isBeforeCutoff = moment(config.cutoff2023).valueOf() >= moment(employment.hiredDate).valueOf();
            return !isBeforeCutoff && enrolled && !employment.isFirstEmploymentInParticipation() && !employment.events.find(x=> x.config.memSusEmailSent) && employment.events.find(x=> x.config.isMultipleEmployer);
        },
        form: <MemSustainedTask isEmployment/>,
    },
    infDed: {
        validation: (employment) => { 
            if(!employment?.participation?.status?.isActive){
                console.warn('EmploymentTaskConfig infDed validation: employment.participation.status.isActive is undefined/null, data might be corrupted');
            }
            if(!employment?.participation?.isPendingClose){
                console.warn('EmploymentTaskConfig infDed validation: employment.participation.isPendingClose is undefined/null, data might be corrupted');
            }
            let enrolled = employment?.participation?.status?.isActive?.() && !employment?.participation?.isPendingClose?.();
            let isBeforeCutoff = moment(config.cutoff2023).valueOf() >= moment(employment.hiredDate).valueOf();
            return !isBeforeCutoff && enrolled && !employment.isFirstEmploymentInParticipation() && !employment.events.find(x=> x.config.deductionSent) && employment.events.find(x=> x.config.isMultipleEmployer);
        },
        form: <YesNoQuestion isEmployment message='Have the employer and employee been informed of automatic deductions?' onYes={(employment, cmt) => {employment.addEvent({code: 'actAutDed', cmt: cmt})}}/>
    },
    ltd65: {
        validation: (employment) => { 
            const isPPEnrolled = employment.participation.status.isActive();
            const isLTD = employment.status.isLtd();
            const isAge65InNext3Months = employment.participation.person.isTurningAgeInNextMonths(65, 3);
            const isOver65 = employment.participation.person.isOverAge(65);
            const hasNOP = employment.participation.hasNOP();

            return isPPEnrolled && isLTD && (isAge65InNext3Months || isOver65) && !hasNOP;
        },
        formalDescription: "LTD benefit likely to stop at age 65. Submit insurance letter terminating LTD benefits.",
    },
    expiredLeave: {
        validation: (employment) => employment.isExpiredAsOf(moment()),
        formalDescription: "Leave Expired: ASH will email data confirmation request.",
    },
}