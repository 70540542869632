import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Table } from '../../../framework/controls'
import Loading from '../../../components/containers/Loading'
import EventSearch from '../../../services/search/EventSearch'
import FilterContainer from '../../../components/containers/FilterContainer'
import EventView from '../../../entities/search/EventSearch/EventView'
import moment from 'moment'
import { ETS_FORMAT } from '../../../framework/utils/helper'
import { ParticipationEvent } from '../../../entities'
import { Definition } from '../../../framework/infra'
import { EmploymentEvent } from '../../../entities/employment'

const FIRST_PAGE = 1;

const dynamicColumnsConfig = [
    // filterProp must exist in EmploymentViewFilters.definitions in src/entities/search/EventSearch/EventView.js
    {prefix: 'participation', eventClass: ParticipationEvent, filterProp: 'periodParticipationStatusKey'},
    {prefix: 'employment', eventClass: EmploymentEvent, filterProp: 'periodEmploymentStatusKey'},
    {prefix: 'participation', eventsPrefix: 'participationNonStatus', eventClass: ParticipationEvent, filterProp: 'periodParticipationNonStatusEventsKey'}
]

const EventReport = () => {

    let pageSize = EventSearch.DEFAUT_PAGE_SIZE;

    const history = useHistory();
    const [summaries, setSummaries] = useState();
    const [currentFilters, setCurrentFilters] = useState({});
    const [paginationInfo, setPaginationInfo] = useState({});
    const exportConfigs = EventView.exportConfigs;



    const getHeader = (tableType) => {
        const width = 120;
        let dynamicColumns = {}

        /**
         * Get the dynamic column definitions and format functions
         * @param {{prefix: string, eventClass: class, filterProp: string, eventsPrefix?: string}} param0 
         * @returns The dynamic columns
         */
        const getDynamicColumns = ({prefix, eventClass, filterProp, eventsPrefix}) => {
            const dynamicColumns = {}
            for (const code of currentFilters[filterProp] ?? []) {
                dynamicColumns[prefix + '-' + code] = { 
                    type: Definition.types.STRING, 
                    text: new eventClass({code}).desc,
                    config: {        
                        width: width,
                        format: (value, instance) => {
                            const filterConfig = EventView.filters?.definitions?.[filterProp];
                            if(typeof filterConfig?.formatDynamicColumnValue === 'function'){
                                const formattedValue = filterConfig.formatDynamicColumnValue(value, instance, code, currentFilters.dateFrom, currentFilters.dateTo);
                                if(formattedValue) return formattedValue;
                            }
                            return '';
                        }}
                }
            }
            return dynamicColumns;
        }

        dynamicColumnsConfig.forEach(dynamicColumnConfig => {
            if(currentFilters[dynamicColumnConfig.filterProp]){
                dynamicColumns = {...dynamicColumns, ...getDynamicColumns(dynamicColumnConfig)}
            }
        })
    
        const columnKeys = [...exportConfigs.columns, ...Object.keys(dynamicColumns)]
        EventView.updateDefinitions(dynamicColumns)
        const columns = new tableType.Headers(EventView, columnKeys); 
    
        for (const key in dynamicColumns) {
            for (const config in dynamicColumns[key].config) {
                columns[key][config] = dynamicColumns[key].config[config];
            }
        }

        return columns;
    }

    const [columns, setColumns] = useState(getHeader(Table));

    useEffect(() => {
        loadEmployments(FIRST_PAGE)
    }, [currentFilters]);

    const handleSelectEmployment = (row) => { 
        history.push(`/employer/${row.employer.code}/employment/${row.id}`);
    }

    const loadEmployments = (pageNumber = FIRST_PAGE, scrollDuration, scrollId) => {
        return search({pageNumber, scrollDuration, scrollId}).then((results) => {
            setSummaries(results?.documents?.all);
            setPaginationInfo({total: results.total, totalPages: results.totalPages, currentPage: pageNumber, pageSize });
            setColumns(getHeader(Table));
        });
    }

    const search = async ({pageNumber = FIRST_PAGE, scrollDuration, scrollId}) => {
        if (currentFilters.pageSize) pageSize = parseInt(currentFilters.pageSize);
        const results = await EventSearch.search(currentFilters, pageNumber, pageSize, scrollDuration, scrollId);

        results?.documents?.all?.forEach(result => {
            dynamicColumnsConfig.forEach(dynamicColumnConfig => {
                if(currentFilters[dynamicColumnConfig.filterProp]){
                    for (const code of currentFilters[dynamicColumnConfig.filterProp] ?? []) {
                        const filterConfig = EventView.filters?.definitions?.[dynamicColumnConfig.filterProp];
                        if(typeof filterConfig?.filterDynamicColumnValue === 'function'){
                            // filters the events in the instance
                            filterConfig.filterDynamicColumnValue(null, result, code, currentFilters.dateFrom, currentFilters.dateTo);
                        }
                    }
                }
            })
        })

        return results;
    }

    const onPageChange = (selectedPageNumber) => loadEmployments(selectedPageNumber);

    const postExportFn = ({ excel, sheet, headers}) => {
        const dateFrom = currentFilters.dateFrom ? moment(Number(currentFilters.dateFrom)).format(ETS_FORMAT): 'N/A';
        const dateTo = currentFilters.dateTo ? moment(Number(currentFilters.dateTo)).format(ETS_FORMAT): 'N/A';

        const message = `Event Date Range: ${dateFrom} to ${dateTo}`;

        excel.addLine(sheet, message);
        return excel;
    }

	return <> 
            {!summaries ? <Loading /> :
                <div className="content-container members-container">
                    <FilterContainer view={EventView} onUpdate={setCurrentFilters} exportFn={search} exportHeaderFn={getHeader} postExportFn={postExportFn} filterBoxWidth={300}/>
                    <Table id='employments-table' 
                        data={summaries} 
                        columns={columns} 
                        className="mt-2"
                        editable
                        sort='name'
                        onSelect={handleSelectEmployment}
                        fitHeightToContent
                        isPaginated
                        paginationInfo={{onPageChange, ...paginationInfo }}
                    />
                </div>
            }
    </>;
}

export default EventReport;