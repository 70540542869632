import { RefHistorical } from '../../framework/infra'
import Spouse from './Spouse'

export default class SpouseHistory extends RefHistorical {

    static ref = Spouse;

    removeById(spouseId) {
        this._list = this._list.filter(x=>x.id !== spouseId);
        return this._list;
    }

    sort() {
        this._list = this._list.sort((a,b)=> {
            if (a.ets === b.ets) return a.rts - b.rts;
            return a.ets - b.ets;
        });
        return this._list;
    }

    findSpouse(jurisdiction) {
        if (this._list.length === 0) return;
        let spouse;
        for (const currentSpouse of this.sort().filter(x=> x.jurisdiction === jurisdiction || x.jurisdiction === 'both')) {
            if (!spouse) spouse = currentSpouse;
            else {
                switch (currentSpouse.mSts) {
                    case 'commonLaw': 
                        if(jurisdiction === 'fed' || !spouse.isMarried) spouse = currentSpouse;
                        break;
                    case 'single': 
                        let hasPreviousMarriage = spouse.isMarried;
                        if (!hasPreviousMarriage) spouse = currentSpouse;
                        break;
                    case 'widowed': 
                        let hasPreviousMarriageToDifferentPerson = spouse.isMarried && spouse.person.id !== currentSpouse.person.id;
                        if (!hasPreviousMarriageToDifferentPerson) spouse = currentSpouse;
                        break;
                    case 'divorced': 
                    case 'separated': 
                        spouse = currentSpouse;
                        break;
                    case 'married': 
                        if(jurisdiction === 'que' || !spouse.isMarried) spouse = currentSpouse;
                        break;
                    default:
                        break;
                }
            }
        }
        return spouse;
    }

    static definitions = { 
        
    }
}
