
import { Definition, RefEnum } from '../../framework/infra'

export default class EmploymentStatus extends RefEnum {
    
    isActive() { return ['act', 'apr'].includes(this.key) }
    isProgressiveReturn() { return this.key === 'apr'} 
    
    isLayOff() { return this.key === 'tlo' }
    isTerminated() { return ['trm', 'tfq', 'tcl', 'trf', 'trd', 'tde', 'tex'].includes(this.key) }
    isNotTerminated() { return !this.isTerminated() }
    isPermanentTerminated() { return ['trm', 'tfq', 'tcl', 'trf', 'trd', 'tde', 'tex'].includes(this.key) }

    isFiredQuit() { return  ['tcl', 'tfq', 'trf', 'tex'].includes(this.key) }
    isTransfer() { return this.key === 'trf'}
    isRetired() { return this.key === 'trd' }
    isDeceased() { return this.key === 'tde' }
    isMaternity() { return this.key === 'mat' }
    isLtd() { return this.key === 'ltd' }
    isLoa() { return this.key === 'loa' }
    isLst() { return this.key === 'lst' }
    isOnLeave() { 
        return [
            'loa', 'lpp', 'lpl', 'lpy', 'lcc', 'lco', 'lad', 
            'lst', 'lso', 'leo', 'led', 'tlo', 'lsw', 'lms', 
            'lun', 'lsp'
        ].includes(this.key);
    }
    isLeaveException() {
        return (this.isLtd() || this.isLst() || this.isMaternity()) && this.isOnLeave()
    }
    eligibleForSelfContribution() { 
        return ['lpp', 'lpl', 'lpy', 'lcc', 'lco', 'lad', 'lst']
            .includes(this.key);
    }
    isDeemedStatus() { return this.eligibleForSelfContribution() || this.isLtd() || this.isMaternity() }

    static types = {
        act: new EmploymentStatus('act', 'Employed'),
        apr: new EmploymentStatus('apr', 'Progressive Return'),
        mat: new EmploymentStatus('mat', 'Maternity'),
        loa: new EmploymentStatus('loa', 'Leave of Absence'),
        ltd: new EmploymentStatus('ltd', 'Long Term Disability'),
        lpp: new EmploymentStatus('lpp', 'Parental/Paternity'),
        lpl: new EmploymentStatus('lpl', 'Parental'),
        lpy: new EmploymentStatus('lpy', 'Paternity'),
        lcc: new EmploymentStatus('lcc', 'Compassionate care/Adoption'),
        lco: new EmploymentStatus('lco', 'Compassionate care'),
        lad: new EmploymentStatus('lad', 'Adoption'),
        lst: new EmploymentStatus('lst', 'Approved Medical Leave (ie: STD, CNESST)'),
        leo: new EmploymentStatus('leo', 'Education/Sabbatical/Other'),
        lso: new EmploymentStatus('lso', 'Other'),
        led: new EmploymentStatus('led', 'Education leave (Full Time Student)'),
        lms: new EmploymentStatus('lms', 'Military Service'),
        lsw: new EmploymentStatus('lsw', 'Suspended without pay'),
        lsp: new EmploymentStatus('lsp', 'Suspended with pay'),
        lun: new EmploymentStatus('lun', 'Leave Unspecified'),
        trm: new EmploymentStatus('trm', 'Employment Terminated'),
        tcl: new EmploymentStatus('tcl', 'Employment Closed'),
        tlo: new EmploymentStatus('tlo', 'Lay-off (intention to rehire)'),
        tfq: new EmploymentStatus('tfq', 'Fired/Quit'),
        trd: new EmploymentStatus('trd', 'Retired'),
        tde: new EmploymentStatus('tde', 'Deceased'),
        trf: new EmploymentStatus('trf', 'Transferred Out'),
        tex: new EmploymentStatus('tex', 'Leave Expired'),
    }
    static default = new EmploymentStatus('', 'Not Recognized')
}

