import { Definition, Ref, RefEvent, RefHistorical } from '../../framework/infra'
import { moment } from '../../framework/utils/helper'
import { Period } from '../../framework/utils'

import { EmploymentMessages } from './EmploymentMessages'
import EmploymentTasks from './EmploymentTasks'
import { EmploymentEvents, EmploymentEvent } from './EmploymentEvent'
import EmploymentStatus from './EmploymentStatus'
import WorkSchedule from './EmploymentWorkSchedule'
import Employments from './Employments'
import config from '../../utils/config'
import { Spouse } from '../person'

export const EMPLOYMENT_SOURCE = {
    REMITTANCE: {key: 'rem', text: 'This employment was created through the remittance upload'},
    IMPORT:  {key: 'eli', text: 'This employment was created through the eligibility Upload'},
    MANUAL:  {key: 'man', text: 'This employment was created manually'}
}

export default class Employment extends Ref {
    get person() {
        if(!this.participation) {
            console.warn('Employment get person: this.participation is undefined/null, data might be corrupted');
        }
        return this.participation?.person;
    }

    get eventStatuses() { 
        const filteredEvents = this.events.getFiltered(eve => eve.status && eve.status !== EmploymentStatus.default);
        filteredEvents.sortEvents();
        return filteredEvents;
     }
    get statuses() { return this.eventStatuses.map(eve => eve.status) }
    get status() { return this.statuses[this.statuses.length - 1] }
    get statusDesc() { return this.status.desc + ' - (' + this.participation.events.statusDesc + ')'}
     
    get event() { return this.events.last || new EmploymentEvent()}
    set event(ev) { this.addEvent(ev) }

    get hiredDate() {
        const hiredEvent = this.getHiredEvent()
        return hiredEvent ? hiredEvent.effDt : ''
    }

    get payrollStartDate() {
        const payrollStartDateEvent = this.getPayrollStartDateEvent()
        return payrollStartDateEvent ? payrollStartDateEvent.effDt : ''
    }

    get weeklySch() { return this.workSch?.weeklySchedule ?? '' }
    set weeklySch(val) {
        const histItem = this.workSchHistory.create();
		histItem.value = new WorkSchedule(val);
		this.workSchHistory.addNewHistoricalItem(histItem);
    }

    get fieldsHistory() {
        this.baseEarningsHistory.setHistoryDesc(this.constructor.definitions['baseEarnings']) //TODO make all this generics
        this.isCQHistory.setHistoryDesc(this.constructor.definitions['isCQ'])
        this.isNHistory.setHistoryDesc(this.constructor.definitions['isN'])
        this.isTPHistory.setHistoryDesc(this.constructor.definitions['isTP'])
        this.workSchHistory.setHistoryDesc(this.constructor.definitions['workSch'])
        this.employmentTypeHistory.setHistoryDesc(this.constructor.definitions['employmentType'])

        const hist = new RefHistorical()
        hist.pushList(this.__baseEarnings).pushList(this.__isCQ).pushList(this.__employmentType).pushList(this.__workSch).pushList(this.__isN).pushList(this.__isTP)
        hist.reorder()
        return hist.history
    }
    
    get combinedHistory() {
        return RefHistorical.combine([this.events, this.tasks, this.fieldsHistory]) //This is for the employment history table
    }
    get combinedWarnings() { return RefHistorical.combine([this.messages, this.tasks]) }
    get combinedAllWarnings() { return RefHistorical.combine([this.combinedWarnings, this.participation.combinedWarnings, this.participation.membership.combinedWarnings])}
    get startEndDesc() { return this.employer.code + ' - ' + (this.hiredDate || 'NO HIRED DATE') + (this.status && this.status.isTerminated() ? (' -> ' + this.event.effDt + ' (' + this.status.desc + ')') : '')}

    get firedQuitDate(){ return !this.status.isActive() && !this.status.isOnLeave() && this.events.reverse().find((ev) => ev.status.isFiredQuit())?.ets }
    get sourceText () { 
        for (const source in EMPLOYMENT_SOURCE) {
            if(EMPLOYMENT_SOURCE[source].key === this.source) return EMPLOYMENT_SOURCE[source].text;
        } 
        return '';
    }

    /** get the spouse, or return a new spouse with marital status Single */
    get spouse () {
        return this.getSpouseNoDefault() ?? new Spouse({mSts: 'single', jurisdiction: this.employer.jurisdictionCode, parent: this.person.id});
    }
    /** get the spouse, does not return a new default Single spouse if no spouse */
    getSpouseNoDefault() {
        return this.person.findSpouse(this.employer.jurisdictionCode);
    }

    isMERLayOff(date){
        const onLeave = this.eventStatuses.last.status.isOnLeave();
        const isExpired = this.eventStatuses.last.isExpired(date, this.employer.jurisdictionCode, this.participation.getActiveStatusEvent());
        const isMER = this.participation.isMER
        return onLeave && isExpired && isMER;
    }
 
    getPeriodEmploymentType(ts) {
        return this.employmentTypeHistory.getAt(ts)?.value;
    }

    isPartTime(ts) {
        return this.getPeriodEmploymentType(ts) === 'pt';
    }

    isCasual(ts){
        return this.getPeriodEmploymentType(ts) === 'cs';
    }

    getContributingDaysInYear(period, isReport=false) {
        const periods = isReport || period.yearEnd ? Period.getPeriods(Period.create(period.year + '01'), Period.create(period.year + '12')) : [period];
        return periods.reduce((days, period) => {
            const participationStatusEvent = this.participation.eventStatuses.getAt(period.timestampAtPeriodEnd);
            const participationStatus = participationStatusEvent?.status;
            if(participationStatus?.isEligible() && participationStatus?.isActive()){
                const empStatusEvent = this.eventStatuses.getAt(period.timestampAtPeriodEnd);
                if(empStatusEvent?.status.isActive()) days += moment(participationStatusEvent.ets).isBefore(moment(empStatusEvent.ets)) ? empStatusEvent.getEffectiveDaysInPeriod(period) : participationStatusEvent.getEffectiveDaysInPeriod(period);
                //If employment event is not active but appears in a mid month
                else if(moment(empStatusEvent.ets).isAfter(moment(period.timestampAtPeriodStart))){
                    const eventsDuringPeriod = this.eventStatuses.getDuring(period.timestampAtPeriodStart, period.timestampAtPeriodEnd);
                    const eventDuringPeriodCopy = [...eventsDuringPeriod]
                    const activeEvent = eventDuringPeriodCopy.reverse().find(ev => ev.status.isActive());
                    const selfContrib =  this.onContributingLeave(period.timestampAtPeriodStart);
                    const deemedEvent = eventsDuringPeriod.find((ev) => ev.status.isDeemedStatus());
                    if (selfContrib && deemedEvent) {
                        days += deemedEvent.getEffectiveDaysInPeriod(period);
                    } else if (activeEvent) {
                        if (activeEvent.endTs < empStatusEvent.ets) {
                            days += activeEvent.getEffectiveDaysInPeriod(period);
                        } else {
                            const activeDay = activeEvent.getEffectiveDaysInPeriod(period);
                            const onLeaveDays = eventsDuringPeriod.getNext(activeEvent).getEffectiveDaysInPeriod(period);
                            days += activeDay - onLeaveDays;
                        }
                    }
                    if(eventsDuringPeriod[eventsDuringPeriod.length - 1]?.status.isFiredQuit()) days += 1;
                }
            }
            if(this.onContributingLeave(period.timestampAtPeriodEnd)){
                days += this.eventStatuses.getAt(period.timestampAtPeriodEnd).getEffectiveDaysInPeriod(period);
            }
            return days
        }, 0)
    }

    getHiredEvent() { return this.events.find(e => e.config.isHiredEvent )}
    getPayrollStartDateEvent() { return this.events.find(e => e.config.payrollStartDate )}
    getMerEvent() { return this.events.find(e => e.config.isMultipleEmployer )}

    earningsInPrev2Months() { //TODO
        return false
    }
    onLeaveNoBaseEarnings() { //This doesn't include all the on leave statuses so should we change the name?
        if(!this.baseEarnings) {
            const period = Period.getLaunchPeriod();
            const hasSelfAccepted = this.events.find(
                (event) => event.selfContribAccepted
            );
            const hasLTD = this.eventStatuses.find((event) =>
                event.status.isLtd() && period.isBefore(new Period(event.ets))
            );
            const hasMaternity = this.eventStatuses.find((event) =>
                event.status.isMaternity() && period.isBefore(new Period(event.ets))
            );
            const hasEligibleSelf = this.eventStatuses.find((event) =>
                event.status.eligibleForSelfContribution() && period.isBefore(new Period(event.ets))
            );
            return (
                hasLTD || hasMaternity || (hasEligibleSelf && hasSelfAccepted)
            );
        }
    }
    onLeaveNoSelfContribution() {//This doesn't include all the on leave statuses so should we change the name?
        if(!this.baseEarnings) {
            return this.eventStatuses.last.status.eligibleForSelfContribution() && (!this.event.config.selfContribAccepted || !this.event.config.selfContribDeclined);
        }
    }
    isStdOver15weeks() { //ASK, should we calculate as days?
        const today = moment().format('YYYY-MM-DD')
        const stdEndDate = moment(this.eventStatuses.last.effDt).add(15, 'weeks').format('YYYY-MM-DD')
        return this.eventStatuses.last.status.isLst() && (stdEndDate < today)
    }
    isOnLeaveOverOneYear() { //This doesn't include all the on leave statuses so should we change the name?
        const today = moment().format('YYYY-MM-DD');
        return this.eventStatuses.last.status.isOnLeave() 
            && !this.participation.status.isActive() 
            && this.participation.events.last.code !== 'a60Nop' 
            && moment(this.eventStatuses.last.effDt).add(1, 'year').format('YYYY-MM-DD') < today;
    }
    isMaternityOver18weeks() {
        const today = moment().format('YYYY-MM-DD')//get weeks from rates maternity duration
        const maternityDuration = this.employer.plan.currentRates.maternityDuration
        const maternityEndDate = moment(this.eventStatuses.last.effDt).add(maternityDuration - 1 , 'days').format('YYYY-MM-DD')
        return this.eventStatuses.last.status.isMaternity() && (maternityEndDate < today)
    }
    isRetiredPackageSent() { return this.eventStatuses.last.status.isRetired() && this.participation.events.last.code.isPackageSent }
    isFiredQuitPackageSent() { return this.eventStatuses.last.status.isFiredQuit() && this.participation.events.last.code.isPackageSent }
    
    hasAgeToRejoin() {
        if(!this.person.dob) return false;
        let agesToRejoinPlan = config.ageToRejoinPlan(moment(this.hiredDate).valueOf());
        let age = this.employer.plan.isFederal() ? agesToRejoinPlan.fed : agesToRejoinPlan.que;
        
        return moment(this.hiredDate).valueOf() <= moment(this.person.dob).add(age ,'years').subtract(1, 'day').valueOf();
    }

    isStillEligible(){
        if(!this.participation?.eligibilityEndDate) {
            console.warn('Employment isStillEligible: this.participation.eligibilityEndDate is undefined/null, data might be corrupted');
        }
        const eligEnd = this.participation?.eligibilityEndDate?.();
        return eligEnd && moment(this.hiredDate).valueOf() < eligEnd.valueOf() && this.participation.status.isEligiblePeriod();
    }

    isSelfContributing(ts) { 
        const eventStatus  = this.eventStatuses.getAt(ts);
        const eventsInterval = this.events.getInterval(eventStatus.ets);
        return eventStatus.status.eligibleForSelfContribution() && eventsInterval.find(ev => ev.config.selfContribAccepted) && !eventsInterval.find(ev => ev.config.selfContribDeclined);
    }

    isSelfContributingDuringPeriod(fromTs, toTs) {
        const periodEvents = this.events.getAllDuringWithEndTs(fromTs, toTs)
        return periodEvents.find(event => event.config.selfContribAccepted) && !periodEvents.find(event => event.config.selfContribDeclined);
    }

    isPartiallySelfContribution(fromTs, toTs){
        const periodEvents = this.events.getAllDuringWithEndTs(fromTs, toTs);
        const partiallyDeclined = periodEvents.find(event => event.config.selfContribDeclined && moment(fromTs).isBefore(moment(event.ets)) && moment(event.ets).day !== 1);
        const partiallyAccepted = periodEvents.find(event => event.config.selfContribAccepted && moment(event.ets).isBefore(moment(partiallyDeclined?.ets)));
        return partiallyAccepted ? partiallyDeclined : false;
    }
    
    isActiveDuringPeriod(period, lastEventPeriod){
        const joinDt = moment(this.participation.joinDt);
        const activeEvBeforeJoinDt = moment(lastEventPeriod?.ets).isSameOrBefore(joinDt);
        const periodBeforeJoinDt =  period.moment.isSameOrBefore(joinDt);
        return lastEventPeriod.status.isActive() && !(activeEvBeforeJoinDt && periodBeforeJoinDt)
    }

    isEmployedDuringPeriod(period){
        const statusAtStart        = this.eventStatuses.getAt(period.timestampAtPeriodStart);
        const statusesDuringPeriod = this.eventStatuses.getDuring(period.timestampAtPeriodStart, period.timestampAtPeriodEnd);
        const allNonTermStatuses   = [statusAtStart, ...statusesDuringPeriod].filter(status => status !== undefined && status.status.isNotTerminated());
        
        return allNonTermStatuses.length > 0;
    }

    isOpenDuringPeriod(period) {
        const statusAtStart        = this.participation.eventStatuses.getAt(period.timestampAtPeriodStart);
        const statusesDuringPeriod = this.participation.eventStatuses.getDuring(period.timestampAtPeriodStart, period.timestampAtPeriodEnd);

        //Include Eligible, Enrolled, or Pending (Just check eligibility status since elig could be ELIG, ACTIVE and PENDING)
        const allOpenStatuses   = [statusAtStart, ...statusesDuringPeriod].filter(e => e !== undefined && e.status.isEligible());
        
        return allOpenStatuses.length > 0;
    }

    closedDuringPeriod(period) {
        const statusAtStart        = this.participation.eventStatuses.getAt(period.timestampAtPeriodStart);
        const statusesDuringPeriod = this.participation.eventStatuses.getDuring(period.timestampAtPeriodStart, period.timestampAtPeriodEnd);

        //Include all Closed events
        const allClosedStatuses   = [statusAtStart, ...statusesDuringPeriod].filter(e => e !== undefined && e.status.isClose());
        
        return allClosedStatuses.length > 0;
    }


    isRelevantForPeriod(period){
        const participationOpenDuringPeriod = this.isOpenDuringPeriod(period);
        const employmentClosedManually = this.eventStatuses.find(x=>x.config.manualClose);

        return participationOpenDuringPeriod && !employmentClosedManually;
    } 

    isCloseInPriorYear(period){
        const terminatedDate = this.firedQuitDate;
        const lastYearPeriod = period.decYear().yearEndPeriod;
        const employmentClosedLastYear = lastYearPeriod.timestampAtPeriodEnd > terminatedDate;

        return employmentClosedLastYear;
    } 

    onContributingLeave(ts) { return this.isSelfContributing(ts) || this.eventStatuses.getAt(ts).status.isMaternity() || this.eventStatuses.getAt(ts).status.isLtd() }

    getTerminationEvent() {
        var terminationEvent = this.participation.events.find(x=>x.config.isMEMTD);
        if (terminationEvent) return terminationEvent;

        [...this.events._list].forEach((event) => {
            if (event.status.isTerminated()) {
                terminationEvent = event;
                return;
            }
        })
        return terminationEvent;
     }

     isExpiredAsOf(date) {
        const lastEmpEvent = this.eventStatuses.last;
        const isExpired = lastEmpEvent.isExpired(date, this.employer.jurisdictionCode, this.participation.getActiveStatusEvent());
        if (isExpired) return lastEmpEvent;
     }

     isFiredQuitWithin60Day(hiredDate){
        if (this.status.isActive()) return;
        const event = this.events.findLast(ev => ev?.status.isFiredQuit())
        if(!event) return false;
        const hiredDt = moment(hiredDate).format('YYYY-MM-DD')
        return moment(event.effDt).add(60, 'days').format('YYYY-MM-DD') >= hiredDt
    }

    getPPEventsDuringEmployment(){
        const timestampStart = this.events.first.ets;
        const lastTerminatedEvent = this.events.findLast(ev => ev.status.isTerminated());
        if(lastTerminatedEvent && lastTerminatedEvent.status.isTransfer()){
            if(this.events.find(ev => ev.code === 'rtw' && moment(ev.ets).isAfter(moment(lastTerminatedEvent.ets)))){
                return this.participation.events;
            }
        }
        return lastTerminatedEvent ? this.participation.events.getAllDuring(timestampStart, lastTerminatedEvent.ets): this.participation.events;
    }

    isFirstEmploymentInParticipation() {
        return this.participation.employments?.all[0]?.keyValue === this.keyValue;
    }


    addEvent(event, params = {}) {
        this.events.pushEvent(event, {employment: this, ...params});
    }
    replaceEvent(oldEvent, newEvent, params = {}) {
        this.events.replaceEvent(oldEvent, newEvent, {employment: this, ...params});
    }
    updateEvent(oldEvent, newEvent, params = {}) {
        this.events.updateEvent(oldEvent, newEvent, {employment: this, ...params});
    }
    deleteEvent(event, params = {}) {
        this.events.deleteEvent(event, {employment: this, ...params});
    }

    static refMap = Employments
    static key = ['employer', 'participation']
    static definitions = {
        employer:  { ref: require('../employment/Employer'), text: 'Employer' },
        participation: { key: true, ref: require('../membership/participation/Participation'), text: 'Participation' },
        
        person: { abstract: true, ref: require('../person/Person') },
        
        noEmp: { type: Definition.types.STRING, text: 'Employee #' }, 
        source: { type: Definition.types.CHOICE, text: 'Employment Source', options: [
            EMPLOYMENT_SOURCE.IMPORT,
            EMPLOYMENT_SOURCE.MANUAL,
            EMPLOYMENT_SOURCE.REMITTANCE,
        ]}, 
        sourceText: { abstract: true, type: Definition.types.STRING, text: 'Employment Source'}, 
        messages: { ref: EmploymentMessages, text: 'Messages' },
        events: { isListRef: true, ref: EmploymentEvents, text: 'Events'},
        tasks: { ref: EmploymentTasks, text: 'Tasks' },

        isCQ: { historical: true, type: Definition.types.YESNO, text: 'CPP/QPP' },
        isN: { historical: true, type: Definition.types.YESNO, text: 'Native' },
        isTP: { historical: true, type: Definition.types.YESNO, text: 'Tax Payer' },
        employmentType: { historical: true, type: Definition.types.CHOICE, text: 'Employment Type', options: [
            { key: 'ft', text: 'Full-Time' },
            { key: 'pt', text: 'Part-Time' },
            { key: 'cs', text: 'Casual'},
        ]}, 
        baseEarnings: { historical: true, type: Definition.types.AMOUNT, text: 'Base Monthly Earnings' },
        workSch: { historical: true, ref: WorkSchedule, text: 'Work Schedule' },
        weeklySch: { abstract: true, type: Definition.types.STRING, text: 'Work Schedule' },
        
        event: {abstract: true, ref: EmploymentEvent, text: 'Status'},
        hiredDate: { abstract: true, type: Definition.types.DATE, text: 'Hire Date'},
        payrollStartDate: { abstract: true, type: Definition.types.DATE, text: 'Payroll Start Date'},
        fieldsHistory: { abstract: true, ref: RefHistorical, text: 'Events'},
        status: { abstract: true, ref: EmploymentStatus, text: 'Status'},
        statusDesc: { abstract: true, type: Definition.types.STRING, text: 'Latest Status'},
        startEndDesc: { abstract: true, type: Definition.types.STRING },
        firedQuitDate: { abstract: true, type: Definition.types.DATE, text: 'Fire/Quit Date' },
    }

}
