import { Definition, RefTask } from '../../framework/infra'
import RefDescription from '../../framework/infra/model/RefDescription'
import { EmploymentEvent } from './EmploymentEvent'
import { EmploymentTaskConfig } from './EmploymentTaskConfig'

export default class EmploymentTask extends RefTask {
    get config() { return { ...EmploymentTaskConfig['default'], ...EmploymentTaskConfig[this.code]}}
    get form() {return EmploymentTaskConfig[this.code]?.form || EmploymentTaskConfig['default'].form }
    get formalDescription() {
        const formalDescription = EmploymentTaskConfig[this.code]?.formalDescription;
        const newDesc = new RefDescription({key: this.code, text: formalDescription, name: this.name, options: this.options});
        return formalDescription ? newDesc?.getDesc(this.params) : (this.flowFormalDescription ?? this.desc);
    }
    hasFlow() {
        return EmploymentTaskConfig[this.code]?.form;
    }

    static messages = [
        ['baseMonthlyEarnings', 'Submit the base monthly earnings', 'Missing BME', 't'],
        ['selfContrib', 'Confirm if they will self contribute starting {effectiveDate}. Offer expires {endOfNextMonth}', 'Eligible to Self', 't'],
        ['onLeaveOverOneYear', 'Member has been on leave for over one year and must be terminated from the plan. Confirm data to process termination calculation and ensure final payout was made.', 'Member has been on leave for over one year - membership to be terminated', 't'],
        ['isStdOver15weeks', 'Member has been on Short-Term Disability for over 15 weeks. Notify A.S. Hayes if member has had LTD claim approved or is in the process of applying.', 'Member has been on Short-Term Disability for over 15 weeks', 't'],
        ['isMaternityOver18weeks', 'Maternity ends {maternityEndDate}. Change status to Parental leave starting on {maternityEndDateNextDay}.', 'Maternity', 't'],
        ['signPenOpt', 'Pension due to start {retiredDate}. Please have the member sign their Pension Options.', 'Member needs to sign their Pension Options', 't'],
        ['signTermOpt', 'Terminated on {firedQuitDate}. Please have the member sign & return their Termination Options.', 'Member needs to sign & return their Termination Options', 't'],
        ['hasOutstandingPackageFed', 'On {outstandingEndDate} date, the member has had an outstanding package for over 6 months. Please assist us to have the member sign their Termination Options.', 'Member has had an outstanding package for over 6 months', 't'],
        ['hasOutstandingPackageQue', 'On {outstandingEndDate} the Options have been outstanding for over 180 days. Please have the member sign their Termination Options.', 'Member has had an outstanding package for over 6 months', 't'],
        ['hasUnclaimedBenefitQue', 'Benefits package mailed on {unclaimedBenefitDate}. Member response required.', 'Unclaimed Benefits', 't'],
        ['confirmFrstDay', 'Confirm first day of work with employer', 'Eligibility', 't'],
        ['confirmHrdDate', 'New employment created through remittance upload. Confirm hire date.', '', 't'],
        ['deemedCheck', 'Actual deemed amounts do not match expected deemed amounts. Verify deemed calculations.', 'Deemed Amounts to verify', 't'],
        ['memStask', 'Send Membership sustained with new employer notice to employee and employer', '', 't'],
        ['infDed','Inform employer and employee that automatic deductions will take place', 'Automatic Deduction','t'],
        ['guessedDt', 'Member has event(s) with guessed date', 'Member has event(s) with guessed date','t'],
        ['ltd65', 'LTD benefit likely to stop at age 65.', 'LTD65','t'],
        ['expiredLeave', 'Leave Expired: (1) Update Status (2) Process termination', 'Expired Leaves','t'],
    ]

    static definitions = {
        form: {abstract: true},
        formalDescription: {abstract: true, type: Definition.types.STRING, text: "Employer Description"},
        flowFormalDescription: {abstract: true, type: Definition.types.STRING, text: "Employer Description"},
    }
    
    static validationTask() {
        return Object.values(this.messages).filter(task => EmploymentTaskConfig[task.key])
    }

    static events = EmploymentEvent.messages
}
