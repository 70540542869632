import React, { useEffect, useState } from "react";
import Title from "../../components/ContentHeaderTitle";
import { useParams } from "react-router-dom";
import { MembershipService } from "../../services";
import Loading from "../../components/containers/Loading";
import { Tabs } from "../../framework/containers";
import MemberParticipations from "./MemberParticipations";
import SpouseDisplay from "./SpouseDisplay";
import BeneficiaryDisplay from "./BeneficiaryDisplay";
import LegacyParticipations from "./LegacyParticipations";
import PersonPanel from "../person/PersonPanel";
import { useHistory } from "react-router-dom";

const getTitle = (member) => {
       return <>Member - {member.person.name}</> 
}

const MemberPage = () => {

    const {memberId} = useParams();
    const [member, setMember] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('ppHistory');
    const history = useHistory();

    useEffect(() => {
        const loadMember = async () => {
            const member = (await MembershipService.get(memberId)) || null;
            setMember(member);
        }

        loadMember(); 
    });

    const finalizeSave = async () => {
        setIsLoading(false);
    }

    //refreshe page after delete
    const handleParticipationDelete = async () => {
        history.go(0);
    }

    if (member === undefined) {
        return <Loading />;
    }

    const loadingContext = {get: isLoading, set: setIsLoading}
    return (
        <div className="d-flex flex-column w-100 h-100 ">
            <Title titleText={getTitle(member)} showBackButton />
            <div className="h-90 content-container no-tabs">
                { loadingContext.get ? <Loading /> :
                <Tabs initial={activeTab} className='h-100' onChange={(tab) => setActiveTab(tab)}>
                    <Tabs.Tab name='ppHistory' title='History'>
                        <MemberParticipations 
                            membership={member} 
                            onSave={finalizeSave}
                            loadingContext={loadingContext}
                            handleDeletePP={handleParticipationDelete} />
                    </Tabs.Tab>
                    <Tabs.Tab name='personalInfo' title='Personal Info'>
                        <PersonPanel person={member.person} detailed={true}/>
                    </Tabs.Tab>
                    <Tabs.Tab name='spouse' title={`Spouse`}>
                        <SpouseDisplay hideTitle membership={member} onClose={() => {}}/>
                    </Tabs.Tab> 
                    <Tabs.Tab name='beneficiary' title={`Beneficiary`}>
                        <BeneficiaryDisplay hideTitle membership={member} onClose={() => {}}/>
                    </Tabs.Tab> 
                    <Tabs.Tab name='legacy' title='Legacy Statuses'>
                        <LegacyParticipations membership={member}/>
                    </Tabs.Tab>
                </Tabs>	}
            </div>
        </div>
    );
}

export default MemberPage;