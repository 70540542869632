import { useState } from "react"
import { promisify } from "util"
import { ExcelRenderer } from 'react-excel-renderer'
import { renderToString as renderComponent } from 'react-dom/server';
import { RefMessage, Ref } from "../../framework/infra";
import { Col, Row, Tabs  } from '../../framework/containers';
import { Button, Table, UploadButton, Icon } from '../../framework/controls';
import { Excel } from '../../framework/utils';
import { renderToString } from '../../framework/utils/renders'
import EmploymentBusiness from "../../business/EmploymentBusiness"
import { array2Object, phraseToPascal, setSafe, toSearchString, isValidDate, today, toEpochTs, isValidSIN, parseExcelDate, isSameAlphaNumStrings } from '../../framework/utils/helper';
import { Employment, Membership, Participation, ParticipationEvent, Person } from '../../entities';
import { EmployerService, EmploymentService, MembershipService, PersonService, ParticipationService } from '../../services';
import { EmploymentEvent } from "../../entities/employment";
import { employmentEventConfigs } from "../../entities/employment/EmploymentConfig";
import EmploymentTasks from "../../entities/employment/EmploymentTasks";

import WorkSchedule from "../../entities/employment/EmploymentWorkSchedule";
import Loader from "../../components/containers/Loader";
import moment from "moment";
import { EMPLOYMENT_SOURCE } from "../../entities/employment/Employment";
import MercerKeyUpload from "./MercerKeyUpload";

const SUPPORTED_TYPES = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

const MemberUpload = () => {
	const [rows, setRows] = useState([]);
	const [activeTab, setActiveTab] = useState('uploaded');
	const [isLoading, setIsLoading] = useState();
	const [processed, setProcessed] = useState(0);
	const [totalItems, setTotalItems] = useState();
	const [file, setFile] = useState();
	const [success, setSuccess] = useState(false);

	const dynamicFields = getFields();
	const personFields = dynamicFields.filter(field => field.entityName === 'Person');
	const employmentFields =dynamicFields.filter(field => field.entityName === 'Employment');
	const participationFields = dynamicFields.filter(field => field.entityName === 'Participation');

	let headers = []
	switch (activeTab) {
		case 'newPeople':
		case 'updatedPeople':
			headers = personFields.map(field => (tabs[activeTab].headersGroup + field.entityName + '.' + field.name)); break;
		case 'newEmployments':	
		case 'updatedEmployments':
			const base = ['person.sin', 'person.name'];
			if (activeTab === 'updatedEmployments') base.push('employment.statusDesc');
			headers = [...base, ...employmentFields.map(field => (tabs[activeTab].headersGroup + field.entityName + '.' + field.name))]; 
			break;
		case 'newParticipation':
		case 'updatedParticipation':
			headers = ['person.sin', 'person.name', ...participationFields.map(field => (tabs[activeTab].headersGroup + field.entityName + '.' + field.name))]; 
			break;
		case 'tasksAfterSave':
			headers = ['person.sin', 'person.name', 'newEmployment.employer.code', 'empTasks']
			break;
		default:
			headers = getFields().map(field => (tabs[activeTab].headersGroup + field.entityName + '.' + field.name));
	}

	//Columns configuration
	const columns = new Table.Headers(FileRow, headers)
	for (const [key] of Object.entries(columns)) {
		if(rows.length > 0)  { 
			columns[key].hideIfEmpty = true; //hide all columns with no data
			columns[key].format = (val, inst) => renderValue(key, val, inst); //render icon showing previous value
		}
		
		const keySplit = key.split('.');
		const name = keySplit[keySplit.length-1];
		const field = getFields().find(x=>x.name === name); 
		if (field) columns[key].title = field.title; //update title based of value in fields and not the entity
	}
	columns['message'] = {name:'message.desc', title: 'Error', width:'200', hideIfEmpty: true};
	columns['empTasks'] = { name:'message.desc', title: 'Employment tasks', /* width:'300', */ hideIfEmpty: activeTab !== 'tasksAfterSave'};
	columns['empTasks'].format = (val, inst) => { // inst: FileRow
		const tasksMessageEls = inst.empTasks?.all?.map?.(x => x.title)?.filter(x => Boolean(x))?.map(x => <li>{x}</li>);
		return tasksMessageEls?.length ? renderToString(<ul>{tasksMessageEls}</ul>) : '';
	};
	const filterData = tabs[activeTab].filter ? rows.filter(tabs[activeTab].filter) : rows;

	const handleUpload = async (file) => {

		//Clear all service caches
		clearCache();

        if (!file) return this.error('noFileSelected');
        if (!SUPPORTED_TYPES.includes(file.type)) return this.error('fileNotSupported');

		const [excelFile, employers] = await Promise.all([promisify(ExcelRenderer)(file), EmployerService.getEmployers()]);
		const employersByCode = array2Object(employers, 'code')
		const fileHeaders = excelFile.rows.splice(0, 1)[0]
		const matches = matchHeaders(fileHeaders)	
		const matchesForPerson = matches.filter(match => match.entityName === 'Person')
		const matchesForEmployment = matches.filter(match => match.entityName === 'Employment')
		const matchesForParticipation = matches.filter(match => match.entityName === 'Participation')
		const activeRows = [];
		const excelRows = excelFile.rows.filter(row => row.length > 0);
		const processedSins = [];
		let processedCount = 0;

		setIsLoading(true);
		setTotalItems(excelRows.length);
		setProcessed(processedSins.length);

		for (let fileRow of excelRows) {
			const row = new FileRow();

			matchesForParticipation.forEach(match => { 
				const val = match.format ? match.format(fileRow[match.index]) : fileRow[match.index]
				if (match.event && val) {
					row.loadedParticipation.events.push(val);
				} else {
					setSafe(row.loadedParticipation, match.name, val)
				}
			})
			matchesForPerson.forEach(match => { row.loadedPerson[match.name] = match.format ? match.format(fileRow[match.index]) : fileRow[match.index] })
			matchesForEmployment.forEach(match => { 
				const val = match.format ? match.format(fileRow[match.index]) : fileRow[match.index];
				if (match.event && val instanceof EmploymentEvent) {
					row.loadedEmployment.events.push(val);
				} else {
					setSafe(row.loadedEmployment, match.name, val)
				}
			});
			// add event from Status fields (event that needed several fields from the row)
			const otherEmpEvent = row.getOtherEmploymentEventFromStatus();
			if(otherEmpEvent) {
				row.loadedEmployment.events.push(otherEmpEvent);
			}
			
			if (!row.loadedPerson.sin) row.message = new MemberUploadMessage({code: 'missingSIN'})
			else if (!isValidSIN(row.loadedPerson.sin)) row.message =  new MemberUploadMessage({code: 'invalidSIN'})
			else if (processedSins.find(x=> x === row.loadedPerson.sin)) row.message = new MemberUploadMessage({code: 'duplicateSIN'})
			else if (!row.loadedEmployment.employer.code ||(row.loadedEmployment.employer.code && !employersByCode[row.loadedEmployment.employer.code])) row.message = new MemberUploadMessage({code: 'invalidErCode'})

			if (!row.message.code) {

				//Find new person and person updates
				const employer = employersByCode[row.loadedEmployment.employer.code];
				const existingPerson = await PersonService.getPersonBySin(row.loadedPerson.sin);
				row.person = existingPerson;

				if (!existingPerson) {
					row.newPerson = row.loadedPerson.clone();
					row.newPerson.touch();
					row.person = row.newPerson;
				} else {
					updateProperties('Person', existingPerson, row, 'merged');
					if (row.mergedPerson.isTouched()) row.person = row.mergedPerson;
				}

				//Find new employments and employment updates
				const existingMembership = row.person.id && await MembershipService.get(row.person.id, { includeEmployments: true });
				const existingEmployment = row.person.id && existingMembership && existingMembership.lastParticipation?.employments.find(ee => ee.employer.code === row.loadedEmployment.employer.code);
				const isClosedOrPending = existingEmployment?.participation.isPendingOrClosed();

				if (!existingEmployment || isClosedOrPending) {
					row.newEmployment = row.loadedEmployment.clone();
					row.newEmployment.participation.membership = row.newPerson.isTouched() ? new Membership({person: row.newPerson}) : existingMembership.clone();
					row.newEmployment.employer = employer;
                    row.newEmployment.employer.plan = employer?.plan;
					row.newEmployment.touch();

					if (!isValidDate(row.newEmployment.hiredDate)) {
						row.message = new MemberUploadMessage({code: 'noHireDate'})
					}

					updateProperties('Employment', row.newEmployment, row, 'new');

					if (row.newPerson.isTouched() || !existingMembership.person) {
						//if the person doesn't exist, no need to go into the flow. We need a new of everything
						row.newEmployment.participation.membership = new Membership({person: row.newPerson})
						row.newParticipation = row.loadedParticipation.clone();
						row.newParticipation.touch();
					} else {
						const lastPPno = existingMembership?.lastParticipation?.no;
						const res = await EmploymentService.createEmployment(row.newEmployment.clone(), EMPLOYMENT_SOURCE.IMPORT, {noCommit: true}); //don't want to update a direct reference so clone, set noCommit to true
						if (res.membership.lastParticipation.no !== lastPPno) { // a new PP was added
							row.newParticipation = res.membership.lastParticipation.clone();
							row.newParticipation.touch();
						} else {
							row.newEmployment.participation = existingMembership.lastParticipation.clone();
						}
					}
					row.employment = row.newEmployment;

				} else {
					row.employment = existingEmployment.clone();
					updateProperties('Employment', existingEmployment, row, 'merged');
					if (row.mergedEmployment.isTouched()) row.employment = row.mergedEmployment;
				}

				if (row.newParticipation.isTouched()) {
					updateProperties('Participation', row.newParticipation, row, 'new', {
						employmentInstance: row.employment,
					});
				} else {
					updateProperties('Participation', row.employment.participation, row, 'merged', {employmentInstance: row.employment});
					if (row.mergedParticipation.isTouched()) {
						row.participation = row.mergedParticipation;
					}
				}

				processedSins.push(row.loadedPerson.sin);
			}

			processedCount++;
			setProcessed(processedCount)
			activeRows.push(row);
		}

		setFile(file);
		setRows(activeRows);
		setIsLoading(false);
	}

	const handleReset = () => { 
		setSuccess(false);
		setFile(null)
		setRows([]) 
		setActiveTab('uploaded');
	}

	const handleSave = async () => {

		//Clear all service caches
		clearCache();
		
		let processedCount = 0;
		setIsLoading(true);
		setProcessed(processedCount);

		for (let row of rows) {
			try {
				if (row.message.code) continue;

				if (row.newPerson.isTouched()) {
					console.log('Creating person...')
					await PersonService.create(row.newPerson)
				};
				if (row.mergedPerson.isTouched()) {
					console.log('Updating person...')
					await PersonService.update(row.mergedPerson)
				};
				if (row.mergedEmployment.isTouched()) {
					console.log('Updating employment...')
					await EmploymentService.update(row.mergedEmployment)
				};

				if (row.newEmployment.isTouched()) {
					const membership = row.person.id && await MembershipService.get(row.person.id, { includeEmployments: true });
					row.newEmployment.participation.membership = row.newPerson.isTouched() ? new Membership({person: row.newPerson}) : membership;
					row.newEmployment.participation.membership.person = row.person;

					const lastPPno = row.newEmployment.participation.membership?.lastParticipation?.no;
					let result = await EmploymentService.createEmployment(row.newEmployment.clone(), EMPLOYMENT_SOURCE.IMPORT);
					if(result.employment) {
						try {
							var clone = result.employment.clone();
							const empRes = EmploymentBusiness.validate(clone);
							row.empTasks = empRes.tasks;
						} catch(err){
							console.error(err);
						}
					}
					
					//can't rely on newParticipation because createEmployment makes the PP 
					if (result.membership.lastParticipation.no !== lastPPno) { 
						row.newParticipation = result.membership.lastParticipation.clone();
						updateProperties('Participation', row.newParticipation, row, 'new', { employmentInstance: result?.employment });

						//extra rule here since employment automatically marks as not ELIGIBLE, this might be false if an ELIG event was added
						if (row.newParticipation.events.find(e => e.isEligibleEvent())) {
							row.newParticipation.events.pullFilter(e => !e.status.isIneligible());
							row.newParticipation.touch();
						}

						if (row.newParticipation.isTouched()) await ParticipationService.updateParticipation(row.newParticipation);
					}
				} 
				 
				if (row.mergedParticipation.isTouched()) {
					console.log('Updating participation...')
					await ParticipationService.updateParticipation(row.mergedParticipation)
				};

				
			} catch (e) {
				console.log(e);
				row.message = new MemberUploadMessage({code: 'unknownError'})
			}

			processedCount++;
			setProcessed(processedCount)
		}

		if(rows.find(row => row.message)) setActiveTab('errors');
		else setActiveTab('tasksAfterSave');
		setSuccess(true);
		setIsLoading(false);
		clearCache(); //need to do this so that the app pull latest data
	}

	const handleDownloadTemplate = () => {
		const type = 'loaded';
		const excel = new Excel('Member-Upload-template.xlsx')
		const headers = new Excel.Headers(FileRow, getFields().map(field => (type + field.entityName + '.' + field.name)))
		for (const property of headers._propNames){
			headers[property].title = getFields().find(x=> type + x.entityName + '.' + x.name === property)?.title
		}
		excel.addSheet(headers, []);
		excel.download()
	}

	const clearCache = () => {
		//Clear all service caches
		PersonService.invalidateCache();
		EmployerService.invalidateCache();
		EmploymentService.invalidateCache();
		MembershipService.invalidateCache();
		ParticipationService.invalidateCache();
	}
	
	return <>
		<Row >
			<Col right>
				<Row className="button-row">
					<Button type="link" className='btn-secondary' onClick={handleDownloadTemplate}>Export Template</Button>
					{file ? 
						<>
							<Button key="cancel" className='btn-primary' onClick={handleReset}>Reset</Button>
							<Button key="save" className='btn-primary' onClick={handleSave} disabled={rows.find(row => row.message.code) || success}>Save</Button>
						</>
						: <UploadButton onUpload={handleUpload} text='Import Data' accept='.xls,.xlsx,.csv' displayfileName={false} /> }
					<MercerKeyUpload />
				</Row>
			</Col>
		</Row>

		<div className="table-container">
			{success && <div>The member information has been updated successfully</div> }
			<>
				{ isLoading ? <Loader processed={processed} total={totalItems}/> :
					<>
						<Tabs initial={activeTab} onChange={tab => setActiveTab(tab)} headerCN='mb-2'> 
							{Object.getOwnPropertyNames(tabs).filter(x=> (!success && x !== 'tasksAfterSave') || (success && (x === 'errors' || x === 'tasksAfterSave'))).map(tabName => {
								return <Tabs.Tab key={tabName} name={tabName} title={`${tabs[tabName].title} (${tabs[tabName].filter && rows.filter(tabs[tabName].filter).length})`} />
							})}
						</Tabs>
						<div className="info-panel">The "{tabs[activeTab].title}" table contains a list of {tabs[activeTab].description}</div>
						<Table id='upload-employees-table' data={filterData} columns={columns}/>
					</>
				}
			</>

		</div>
	</> 
}
export default MemberUpload;

/**
 * Goes through each update function configured in fields array by property,
 * will mark the merged+ employment as touched if any changes are found. 
 * @param {string} entityName For example `Person` or `Employment` or `Participation`
 * @param {Person | Employment | Participation} entity 
 * @param {FileRow} row 
 * @param {string} dest For example `merged` or `new`
 * @param {{employmentInstance?: Employment} | undefined} optionalInstances The optional instances to use as reference.
 * The `employmentInstance` is used in the participation event.
 */
const updateProperties = (entityName, entity, row, dest = '', optionalInstances) => {
	//need to copy to avoid changing the cached object
	const draft = entity.clone();

	getFields().filter(field => field.entityName === entityName).forEach((field) => {
		if (field.update) {
			const updateResponse = field.update(draft, row['loaded' + entityName], field.setter ?? field.name, optionalInstances);
			if (updateResponse.updated) {
				row[dest + entityName] = draft.clone();
				row[dest + entityName].touch();
				if (dest === 'merged') //only for merged items, not for new items
					row.updatedProperties[dest + entityName +'.'+ field.name] = updateResponse.output;
			}
		}
	});

	if(entityName === 'Employment') {
		const updateResponse = updateOtherEmploymentEventFromStatus(draft, row['loaded' + entityName], optionalInstances);
		if (updateResponse?.updated) {
			row[dest + entityName] = draft.clone();
			row[dest + entityName].touch();
			if (dest === 'merged') //only for merged items, not for new items
			{
				Object.keys(row.extraData?.employment ?? {}).forEach((extraDataFieldName) => {
					row.updatedProperties[dest + entityName +'.'+ extraDataFieldName] = updateResponse.output;
				});
			}
		}
	}
}
/*
	Will render a tooltip containing the value before the update to visualize changes
*/
const hasOldValue = (key, value, instance) => {
	if (instance.updatedProperties.hasOwnProperty(key)) {
		const val = instance.updatedProperties[key]?.oldVal;
		const date = instance.updatedProperties[key]?.date;
		const isEmpty = val === '' && val !== 'null' && val !== undefined;
		return renderComponent(<><Icon 
				icon={isEmpty ? 'plus-circle' : 'exclamation-circle'}
				tooltip={(isEmpty ? '' : 'Was ') + "'" + (isEmpty ? 'New value' : val)  + "'" + (date ? ' at ' + date : '')}
				tooltip-right
				className={'text-primary' + (isEmpty ? '-dim' : '')}
			/> {instance.updatedProperties[key]?.newVal ?? value}</>)
	}
	return value;
}

const renderValue = (key, value, instance) => {
	//Todo implement custom getters for each field if needed

	return hasOldValue(key, value, instance);
}


const tabs = {
	uploaded: {title: 'Uploaded Data', description: 'items loaded and parsed from the excel sheet. Incorrect formats will show as blank cells.'  , headersGroup: 'loaded', filter: () => true},
	errors: {title: 'Errors', description: 'critical errors preventing changes to be saved.', headersGroup: 'loaded', filter: (row) => row.message.code},
	tasksAfterSave: {title: 'Tasks', description: 'further actions that are required', headersGroup: 'loaded', filter: (row) => row.empTasks?.length},
	newPeople: {title: 'New People', description: 'new people that will be created once changes are saved.', headersGroup: 'new', filter: (row) => row.newPerson?.isTouched()},
	updatedPeople: {title: 'Updated People', description: 'existing people that will have values updated once changes are saved.', headersGroup: 'merged', filter: (row) =>  { return row.mergedPerson?.isTouched() }},
	newEmployments: {title: 'New Employments', description: 'new employments that will be created once the changes are saved.', headersGroup: 'new', filter: (row) => row.newEmployment?.isTouched()}, 
	updatedEmployments: {title: 'Updated Employments', description: 'existing employments that will have values updated once changes are saved.', headersGroup: 'merged', filter: (row) => row.mergedEmployment?.isTouched()},
	newParticipation: {title: 'New Participations', description: 'new participations that will be created once the changes are saved.', headersGroup: 'new', filter: (row) => row.newParticipation?.isTouched()}, 
	updatedParticipation: {title: 'Updated Participations',description: 'existing participations that will have values updated once changes are saved.', headersGroup: 'merged', filter: (row) => row.mergedParticipation?.isTouched()},
}
//Order of these fields are important, hire date should come first because it is referred to in Historical Fields
const fields = [
	{entityName: 'Employment', name: 'employer.code', title: 'ER Code', alias:['code'], 
		format: (val) => val && String(val).toUpperCase(), key: true},
	{entityName: 'Person', name: 'sin', title: 'SIN', alias:['sin'], key: true },
	{entityName: 'Employment', event: true, name: 'hiredDate', title: 'Hired Date', alias:['hiredate'], 
		format: (val) => toEmploymentEvent(val, 'hrd'),
		update: updateOrAddHiredEvent},
	{entityName: 'Employment', event: true, name: 'payrollStartDate', title: 'Payroll Start Date', alias:[], 
		format: (val) => toEmploymentEvent(val, 'psd'),
		update: updateOrAddPayrollStartDateEvent},
	{entityName: 'Person', name: 'firstName', title: 'First Name', alias:[], 
		format: phraseToPascal, 
		update: updateSimple},
	{entityName: 'Person', name: 'lastName', title: 'Last Name', alias:[], 
		format: phraseToPascal, 
		update: updateSimple},
	{entityName: 'Person', name: 'gender', title: 'Sex (male/female)', alias:['gender', 'sex'], 
		format: (val) => toOptionValue(val, Person.definitions.gender.options),
		update: updateSimple},
	{entityName: 'Person', name: 'lng', title: 'Language (en/fr)', alias:['lng', 'language'], 
		format: (val) => toOptionValue(val, Person.definitions.lng.options),
		update: updateSimple},
	{entityName: 'Person', name: 'dob', title: 'Date of Birth', alias:['dob', 'dateofbirth'], 
		format: parseExcelDate,
		update: updateSimple},	
	{entityName: 'Employment', name: 'isN', title: 'Native (y/n)', alias:['isn', 'native'],
		format: toYesNo,
		update: updateHistorical},
	{entityName: 'Employment', name: 'isNDate', title: 'Native Date', alias:['isndate'], 
		format: parseExcelDate,},
	{entityName: 'Employment', name: 'isTP', title: 'Tax Payer (y/n)', alias:['istp', 'taxpayer', 'paystax'],
		format: toYesNo,
		update: updateHistorical},
	{entityName: 'Employment', name: 'isTPDate', title: 'Tax Payer Date', alias:['istpdate', 'paystaxdate'], 
		format: parseExcelDate,},
	{entityName: 'Employment', name: 'noEmp', title: 'Employee #', alias:['noemp', 'noemployee', 'employeeno', 'employeenumber'], 
		format: toBlankIfUndefined,
		update: updateSimple},
	{entityName: 'Employment', name: 'isCQ', title: 'CPP/QPP (y/n)', alias:['iscq', 'payscppqpp', 'cppqpp'],
		format: toYesNo,
		update: updateHistorical},
	{entityName: 'Employment', name: 'isCQDate', title: 'CPP/QPP Date', alias:['iscqdate', 'payscppqppdate'], 
		format: parseExcelDate,},
	{entityName: 'Employment', name: 'weeklySch', setter: 'workSch', title: 'Work Schedule', alias:['worksch'],
		format: (val) => toEnumValue(val, WorkSchedule),
		update: updateHistorical},
	{entityName: 'Employment', name: 'workSchDate', title: 'Work Schedule Date', alias:['workschdate'],
		format: parseExcelDate,},
	{entityName: 'Employment', name: 'employmentType', title: 'Employment Type (pt/st/cs)', alias:['employmenttype'],
		format: (val) => toOptionValue(val, Employment.definitions.employmentType.options),
		update: updateHistorical},
	{entityName: 'Employment', name: 'employmentTypeDate', title: 'Employment Type Date', alias:['emptypedate'],
		format: parseExcelDate,},
	{entityName: 'Participation', event: true, name: 'eligibilityDt', title: 'Eligibility Date', alias:['meteligibility', 'eligibilitydate'], //Order is important in this array to have metElig date appear before elig date
		format: (val) => toParticipationEvent(val, 'metEligDate'),
		update: updateOrAddMetEligDateEvent},
	{entityName: 'Participation', event: true, name: 'joinDt', title: 'Join Date', alias:[ 'joindate'], 
		format: (val) => toParticipationEvent(val, 'metElig'),
		update: updateOrAddMetEligEvent},
	{entityName: 'Participation', event: true, name: 'nonEligibilityDt', title: 'Not Eligible Date', alias:['noneligibility', 'noneligibilitydate'], 
		format: (val) => toParticipationEvent(val, 'inegDes'),
		update: updateOrAddNonEligEvent},
	{entityName: 'Employment', event: false, name: 'eventStatus', title: 'Status', alias:['status', 'status31dec'], 
		format: findEmploymentEventCode
	},
	{entityName: 'Employment', event: true, name: 'eventStatusDate', title: 'Status Date', alias:['statusdate'], 
		format: parseExcelDate
	},
]

/**
 * Gets all fields and extra dynamic fields such as comments for events
 * @returns {array}
 */
const getFields = () => {
	const allFields = []
	fields.forEach(field => {
		allFields.push(field)
		if(field.event) {
			allFields.push({
				entityName: field.entityName,
				name: field.name + 'Comment',
				title: field.title + ' Comment',
				alias: field.alias.map(a => a + 'comment'),
				format: toCleanString,
			})
		}
	});
	return allFields
}

const matchHeaders = (fileHeaders) => {
	fileHeaders = fileHeaders.map(h => toSearchString(h))
	const mapped =  getFields().reduce((mapped, field) => {
		const index = fileHeaders.findIndex(header => {
			return field.alias.includes(header) || toSearchString(field.title) === header
		})
		if(index >= 0) mapped.push(Object.assign({}, field, {index}))
		return mapped
	}, [])
	
	return mapped;

}

export class MemberUploadMessage extends RefMessage {
	static messages = [
		['missingSIN', 'No SIN provided', 'e'],
		['duplicateSIN', 'This SIN already appears in this file', 'e'],
		['invalidSIN', 'Invalid SIN number', 'e'],
		['invalidErCode','Invalid Employer Code', 'e'],
		['missingErCode', 'Employer Code Missing', 'e'],
		['noHireDate', 'A hire date is required to add a new employment', 'e'],
		['invalidStatusDate', 'The status date is not valid', 'e'],
		['missingStatusDate', 'The status date is missing', 'e'],
		['missingOrInvalidStatus', 'The status is not valid or is missing', 'e'],
		['unknownError', 'An unknown error occured', 'e']
	]
}
class FileRow extends Ref {
	constructor(data) {
        super(data);
		this.updatedProperties = {};
    }
	static definitions = {
		//People
		loadedPerson: { ref: Person, text: 'Person'},
		newPerson: { ref: Person, text: 'Person'},
		mergedPerson: { ref: Person, text: 'Person'},
		person: { ref: Person, text: 'Person'},
		//Employments
		loadedEmployment: { ref: Employment, text: 'Employment'},
		newEmployment: { ref: Employment, text: 'Employment'},
		mergedEmployment: { ref: Employment, text: 'Employment'},
		employment: { ref: Employment, text: 'Person'},
		//Participations
		loadedParticipation: { ref: Participation, text: 'Participation'},
		newParticipation: { ref: Participation, text: 'Participation'},
		mergedParticipation: { ref: Participation, text: 'Participation'},
		participation: { ref: Participation, text: 'Person'},
		//Errors or warnings
		message: {ref: MemberUploadMessage, text: 'Message'},
		// tasks after save
		empTasks: {ref: EmploymentTasks, text: 'Employment tasks'}
	}

	getOtherEmploymentEventFromStatus() {
		/** Value is an EmploymentEvent's code if found, or undefined */
		const employmentEventCode = this.loadedEmployment?.['eventStatus'];
		const eventStatusDate = this.loadedEmployment?.['eventStatusDate'];

		if(!eventStatusDate && !employmentEventCode) {
			return null;
		}

		if(eventStatusDate && !isValidDate(eventStatusDate)){
			console.error('invalidStatusDate for employmentEventCode', employmentEventCode, eventStatusDate, {loadedEmployment: this.loadedEmployment});
			this.message = new MemberUploadMessage({code: 'invalidStatusDate'});
			return null;
		}
		if(employmentEventCode && !eventStatusDate){
			console.error('missingStatusDate for employmentEventCode', employmentEventCode, {loadedEmployment: this.loadedEmployment});
			this.message = new MemberUploadMessage({code: 'missingStatusDate'});
			return null;
		}
		if(!employmentEventCode && eventStatusDate){
			console.error('missingOrInvalidStatus for eventStatusDate', eventStatusDate, {loadedEmployment: this.loadedEmployment});
			this.message = new MemberUploadMessage({code: 'missingOrInvalidStatus'});
			return null;
		}

		const event = toEmploymentEvent(eventStatusDate, employmentEventCode);
		return event;
	}

}

/*
	**UPDATERS**
	These functions are used to update drafts and detect updates or give errors if there are any. 
*/
class UpdateResponse {
	constructor(updated, error) {
		this.updated = updated ?? false;
		this.error = error;
    }
}

function updateSimple(instance, loadedInstance, property) {
	const res = new UpdateResponse();
	const newValue = loadedInstance[property];
	if (newValue && newValue !== '' && instance[property] !== newValue) {
		res.output = {property: property, oldVal: instance[property] }
		instance[property] = newValue;
		res.updated = true;
	}
	return res;
}

function updateHistorical(instance, loadedInstance, property) {

	const res = new UpdateResponse();
	const newValObj = loadedInstance[property];
	let newValString;

	if (newValObj instanceof Ref) { 
		//might be enum so we need to access by key
		newValString = newValObj.key ?? '';
	} else {
		newValString = newValObj?.toString();
	}

	const date = getHistoricalDate(instance, loadedInstance, property);
	let val = instance[property + 'History'].getAt(toEpochTs(date))?.value;
	if(Object.hasOwn(val, 'key')) val =  val.key;

	
	if (newValString !== '' && val?.toString() !== newValString) {
		const histItem = instance[property + 'History'].create();
		histItem.value = newValObj;
		histItem.ets = toEpochTs(date)
		instance[property + 'History'].addNewHistoricalItem(histItem);
		res.updated = true;
		res.oldVal = val ?? '';
		res.output = {property: property, oldVal: val ?? '', newVal: newValString, date: date}
	}

	return res;
}

/**
 * Update or add a "HiredEvent" event (code: `hrd`) in the Employment
 * @param {Employment} instance Employment instance
 * @param {*} loadedInstance The loaded row
 * @param {string} property Property name, for example `hiredDate`
 * @returns {UpdateResponse}
 */
function updateOrAddHiredEvent(instance, loadedInstance, property) {
	const res = new UpdateResponse();
	const newValue = loadedInstance[property];
	if (isValidDate(newValue) && newValue !== instance[property]) {
		res.output = {property: property, oldVal: instance[property] }
		instance[property] = newValue;
		const hiredEvent = loadedInstance.getHiredEvent() ?? {code: 'hrd', effDt: newValue};
		if(instance.getHiredEvent()) {
			instance.updateEvent(instance.getHiredEvent(), assignComment(hiredEvent, property, loadedInstance))
		} else {
			instance.addEvent(assignComment(hiredEvent, property, loadedInstance));
		}
		res.updated = true;
	}
	return res;
}

/**
 * Update or add a "PayrollStartDateEvent" event (code: `psd`) in the Employment
 * @param {Employment} instance Employment instance
 * @param {*} loadedInstance The loaded row
 * @param {string} property Property name, for example `payrollStartDate`
 * @returns {UpdateResponse}
 */
function updateOrAddPayrollStartDateEvent(instance, loadedInstance, property) {
	const res = new UpdateResponse();
	const newValue = loadedInstance[property];
	if (isValidDate(newValue) && newValue !== instance[property]) {
		res.output = {property: property, oldVal: instance[property] }
		instance[property] = newValue;

		const payrollStartDateEvent = loadedInstance.getPayrollStartDateEvent() ?? {code: 'psd', effDt: newValue};
		if(instance.getPayrollStartDateEvent()) {
			instance.updateEvent(instance.getPayrollStartDateEvent(), assignComment(payrollStartDateEvent, property, loadedInstance));
		} else {
			instance.addEvent(assignComment(payrollStartDateEvent, property, loadedInstance));
		}
		res.updated = true;
	}
	return res;
}

/**
 * Update or add a "met eligibility" ("joined") event (code: `metElig`) in the Participation
 * @param {Participation} instance Participation instance
 * @param {*} loadedInstance The loaded row
 * @param {string} property Property name, for example `joinDt`
 * @param {{employmentInstance?: Employment} | undefined} optionalInstances The optional instances to use as reference. The `employmentInstance` is used in the participation event
 * @returns {UpdateResponse}
 */
function updateOrAddMetEligEvent(instance, loadedInstance, property, optionalInstances) {
	const res = new UpdateResponse();
	const newValue = loadedInstance[property];
	const oldEvent = instance.events.all.find(ev => Boolean(ev.config.isEnrollEvent));
	const newEvent = loadedInstance.events?.find(ev => ev.code === 'metElig');
	if (newEvent && newEvent.effDt !== oldEvent?.effDt) {
		res.output = {property: property, oldVal: oldEvent?.effDt ?? '' }
		
		const event = newEvent;
		if(oldEvent) {
			instance.updateEvent(oldEvent, assignComment(toEmploymentEvent(event.effDt, oldEvent.code), property, loadedInstance), {openEmployment: optionalInstances?.employmentInstance, employment: optionalInstances?.employmentInstance });
		} else {
			instance.addEvent(assignComment(event, property, loadedInstance), {openEmployment: optionalInstances?.employmentInstance });
		}
		res.updated = true;
	}
	if (isValidDate(newValue) && newValue !== instance[property]) {
		res.output = {property: property, oldVal: instance[property] }
		instance[property] = newValue;

		res.updated = true;
	}
	return res;
}

/**
 * Update or add a "met eligibility date" event (code: `metEligDate`) in the Participation
 * @param {Participation} instance Participation instance
 * @param {*} loadedInstance The loaded row
 * @param {string} property Property name, for example `eligibilityDt`
 * @param {{employmentInstance?: Employment} | undefined} optionalInstances The optional instances to use as reference.
 * The `employmentInstance` is used in the participation event.
 * @returns {UpdateResponse}
 */
function updateOrAddMetEligDateEvent(instance, loadedInstance, property, optionalInstances) {
	const res = new UpdateResponse();
	const oldEvent = instance.events.find(ev => ev.code === 'metEligDate' && (
		// if we passed an employment reference, use it to find the existing event
		(optionalInstances?.employmentInstance && ev.pointers?.length &&
		ev.pointers?.find(p => p.name === 'employer' && (p.instance?.id === optionalInstances?.employmentInstance?.employer?.id)) 
		) 
		// because toParticipationEvent() adds the event without a pointer
		|| !ev.pointers?.find(p => p.name === 'employer')
	));
	const newEvent = loadedInstance.events?.find(ev => ev.code === 'metEligDate');
	if (newEvent && (newEvent.effDt !== oldEvent?.effDt || (oldEvent && oldEvent.effDt === newEvent.effDt && !oldEvent.pointers?.length))) {
		res.output = {property: property, oldVal: oldEvent?.effDt ?? '' }

		const event = newEvent;
		if(oldEvent) {
			instance.updateEvent(oldEvent, assignComment(event, property, loadedInstance), {openEmployment: optionalInstances?.employmentInstance });
		} else {
			instance.addEvent(assignComment(event, property, loadedInstance), {openEmployment: optionalInstances?.employmentInstance }); 
		}
		res.updated = true;
	}

	return res;
}

/**
 * Update or add a "Not Eligible - As designated by Employer" event (code: `inegDes`) in the Participation
 * @param {Participation} instance Participation instance
 * @param {*} loadedInstance The loaded row
 * @param {string} property Property name, for example `nonEligibilityDt`
 * @param {{employmentInstance?: Employment} | undefined} optionalInstances The optional instances to use as reference.
 * The `employmentInstance` is used in the participation event.
 * @returns {UpdateResponse}
 */
function updateOrAddNonEligEvent(instance, loadedInstance, property, optionalInstances) {
	const res = new UpdateResponse();
	const newValue = loadedInstance[property];
	const newEvent = loadedInstance.events?.find(ev => ev.code === 'inegDes');
	const oldEvent = instance.events.find(ev => ev.code === 'inegDes' && (
		// if we passed an employment reference, use it to find the existing event
		(optionalInstances?.employmentInstance && ev.pointers?.length &&
		ev.pointers?.find(p => p.name === 'employer' && p.instance?.id === optionalInstances?.employmentInstance?.employer?.id) 
		)
		// because toParticipationEvent() adds the event without a pointer
		|| !ev.pointers?.find(p => p.name === 'employer'))
		&& (newEvent?.effMoment && ev?.effMoment ? ev.effMoment.year() === newEvent.effMoment.year() : false)
	);
	if (newEvent && (newEvent.effDt !== oldEvent?.effDt || (oldEvent && oldEvent.effDt === newEvent.effDt && !oldEvent.pointers?.length))) {
		res.output = {property: property, oldVal: oldEvent?.effDt ?? '' }
		
		const event = newEvent;
		if(oldEvent) {
			const options = {openEmployment: optionalInstances?.employmentInstance, employment: optionalInstances?.employmentInstance };
			instance.updateEvent(oldEvent, assignComment(event, property, loadedInstance), options);
		} else {
			const options = {openEmployment: optionalInstances?.employmentInstance };
			instance.addEvent(assignComment(event, property, loadedInstance), options);
		}
		res.updated = true;
	}
	if (isValidDate(newValue) && newValue !== instance[property]) {
		res.output = {property: property, oldVal: instance[property] }
		instance[property] = newValue;

		res.updated = true;
	}
	return res;
}

/**
 * Processes both `eventStatus` and `eventStatusDate` in the Employment
 * @param {Employment} employmentInstance The Employment instance
 * @param {*} loadedEmploymentInstance The loaded row
 * @param {*} optionalInstances The optional instances to use as reference.
 * @returns {UpdateResponse}
 */
function updateOtherEmploymentEventFromStatus(employmentInstance, loadedEmploymentInstance, optionalInstances) {
	const res = new UpdateResponse();

	const eventStatusDate = loadedEmploymentInstance['eventStatusDate'];

	const instanceEventAt = employmentInstance.eventStatuses?.getAt?.(moment(eventStatusDate).valueOf());
	const event = loadedEmploymentInstance.events.all.filter(ev => employmentEventConfigs[ev.code]?.useInMembersUploadStatusUpdate)[0];
	const needUpdate = event?.status?.key && instanceEventAt?.status?.key !== event.status.key;

	if(needUpdate) {
		// the loaded event is not the same status as the event from the instance
		const eventWithComment = assignComment(event, 'eventStatusDate', loadedEmploymentInstance);
		employmentInstance.addEvent(eventWithComment);
		res.updated = true;
	} else {
		// nothing to do, status is already the same
	}

	return res;
}

/**
 * Assigns a comment to an event if available and not empty
 * @param {ParticipationEvent | EmploymentEvent} event
 * @param {string} property
 * @returns {ParticipationEvent | EmploymentEvent}
 */
function assignComment(event, property, loadedInstance) {
	const comment = loadedInstance[property + 'Comment'];

	if (comment) {
		event.cmt = comment;
	}
	return event;
}


/*
	**FORMATTERS**
	The functions are used to convert incoming spreadsheet data into data suitable for the entities
*/

function toYesNo(val) {
	if (val === 0) return 'n'
	else if (val === 1) return 'y'
	else if(!val) return ''
	
	val = String(val).toLowerCase()
	if (['missing', 'blank'].includes(val)) return ''
	if (['true', 'y', 'yes'].includes(val)) return 'y'
	return 'n'
}
function toBlankIfUndefined(val) {
	return val ? val : ''
}
function toParticipationEvent(val, code) {
	return val ? new ParticipationEvent({ets: moment(parseExcelDate(val)).valueOf(), code}) : null;
}
/**
 * Get the employment event
 * @param {*} val The date. Can be a number like `45292` or a string like `"17-Dec-23"`
 * @param {*} code The event code
 * @returns an `EmploymentEvent` or `null`
 */
function toEmploymentEvent(val, code) {
	const parsedDate = val ? parseExcelDate(val) : undefined;
	const momentDate = parsedDate ? moment(parsedDate) : (val && typeof val === 'string') ? moment(val) : undefined;
	return momentDate ? new EmploymentEvent({ets: momentDate.valueOf(), code}) : null;
}

/**
 * Find the EmploymentEvent by message
 * @param {string | undefined} employmentEventDescription
 * @returns an `EmploymentEvent`'s code or `undefined`
 */
function findEmploymentEventCode(employmentEventDescription) {
	if(!employmentEventDescription) {
		return undefined;
	}
	// The status string must match exactly with an event description in the employment history
	const eventCode = Object.values(EmploymentEvent.messages).find((v) => isSameAlphaNumStrings(v?.text, employmentEventDescription))?.key;
	
	 if(employmentEventConfigs[eventCode]?.useInMembersUploadStatusUpdate) {
		return eventCode;
	}

	return undefined;
}

function toOptionValue(val, options) {
	val = toSearchString(val)
	if (!val) return ''
	const option = options.find(opt => toSearchString(opt.text) === val || opt.key === val)
	return option ? option.key : '';
}
function toEnumValue(val, enumType) {
	return enumType.types[val] ? val : '';
}

function getHistoricalDate(instance, loadedInstance, property) {
	if(isValidDate(loadedInstance[property + 'Date'])) {
		return loadedInstance[property + 'Date']; //Date from import
	} else if (isValidDate(loadedInstance.hiredDate)) {
		return loadedInstance.hiredDate; //Hire date from import
	} else if (instance.hiredDate) { 
		return instance.hiredDate; //Hire date in DB
	} else return today(); //Default to today
}

/**
 * Removes whitespaces on each end, return undefined if the value is 'missing' or 'blank'
 * @param {string} val Value to clean
 * @returns {string | undefined} Cleaned value
*/
function toCleanString(val) {
	val = val?.trim();
	if (val === '') return undefined;
	return val;
}